import styled from 'styled-components'

export const CloseButton = styled.div`
  color: #808080;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 1000;
`

export const ModalBottom = styled.div`
  height: 280px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ModalContent = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  background: white;
`

export const ModalMid = styled.div`
  height: 100vh;
`

export const ModalTop = styled.div`
  height: 280px;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 1;
  padding-top: 4.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
`

export const RequiredDesc = styled.p`
  color: #ffffff;
  text-align: center;
  max-width: 320px;
  padding: 0 0.75rem;
`

export const RequiredTitle = styled.h2`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
`

export const TakePhoto = styled.div`
  height: 4.5rem;
  width: 4.5rem;
  background: #304ffc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`
