import styled, { css } from 'styled-components'

export const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 1200;
  display: flex;
  background: #d3d3d35c;
  justify-content: center;
  height: 100vh;
  max-width: 500px;
  width: 100%;
  ${({ position }) => positions[position]}
`

const positions = {
  top: css`
    align-items: flex-start;
  `,
  center: css`
    align-items: center;
  `,
  bottom: css`
    align-items: flex-end;
  `,
}
