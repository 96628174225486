import CheckIcon from 'assets/icons/CheckIcon'
import AppModal from 'components/AppModal'

import {
  ActionButtonPrimary,
  ActionButtonSecondary,
  IconWrapper,
  ModalContent,
  ModalDesc,
  ModalTitle,
} from './styled'

const AddMoreDamage = ({ onAddMore, onNoAddMore, position }) => {
  return (
    <AppModal position={position}>
      <ModalContent>
        <IconWrapper>
          <CheckIcon />
        </IconWrapper>
        <ModalTitle>Add More Damage?</ModalTitle>
        <ModalDesc>
          You have completed all the questions for the damaged part.
        </ModalDesc>
        <ActionButtonSecondary onClick={onAddMore}>
          I Have More Damage
        </ActionButtonSecondary>
        <ActionButtonPrimary onClick={onNoAddMore}>
          I Have No Additional Damage
        </ActionButtonPrimary>
      </ModalContent>
    </AppModal>
  )
}

export default AddMoreDamage
