import { useContext, useState, useEffect } from 'react'

import AppFooter from 'components/AppFooter'
import VideoModal from 'components/modals/VideoModal'
import Spinner from 'components/Spinner'
import { AppContext } from 'context/AppContext'
import useAppHistory from 'hooks/useAppHistory'
import { formatAssetUrl } from 'utils/format'

import { HomeContainer } from './styled'

const Welcome = () => {
  const history = useAppHistory()

  const { dispatch, state, types } = useContext(AppContext)

  const [showVideo, setShowVideo] = useState(false)

  const handleClick = () => {
    if (state.callback === 'vin_photo') {
      history.push('identify')
    } else if (state.callback === 'vehicle') {
      history.push('vehicle')
    } else if (state.callback === 'get_vehicle') {
      history.push('getvehicle')
    }
  }

  const toggleVideoModal = () => {
    setShowVideo(!showVideo)
  }

  useEffect(() => {
    if (state.slug) {
      dispatch({
        type: types.FETCH_WELCOME_DATA,
      })
    }
  }, [state.slug])

  if (!state.screen || state.loading) {
    return (
      <HomeContainer>
        <Spinner />
      </HomeContainer>
    )
  }

  return (
    <HomeContainer>
      <div className="logo_wrapper center">
        {state.logo && <img alt="" src={formatAssetUrl(state.logo)} />}
      </div>
      <div className="app_description center">
        <p>{state.desc}</p>
        <div
          className="image-wrapper"
          onClick={toggleVideoModal}
          role="presentation"
        >
          {state.video_link_src && (
            <img alt="" src={formatAssetUrl(state.video_link_src)} />
          )}
        </div>
      </div>
      <AppFooter onClick={handleClick} text={state.next_btn} />
      {showVideo && (
        <VideoModal
          onClose={toggleVideoModal}
          src={state.video_link}
          visible={showVideo}
        />
      )}
    </HomeContainer>
  )
}

export default Welcome
