const CheckIcon = (props) => {
  return (
    <svg
      width="59"
      height="48"
      viewBox="0 0 59 48"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path
        fill="none"
        d="M2 27.5L21.5 45L57 2"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default CheckIcon
