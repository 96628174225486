import styled from 'styled-components'

export const Container = styled.div`
  width: 80%;
  background: white;
  border-radius: 5px;
  padding: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0 10px 10px -5px;
`

export const Percent = styled.div`
  text-align: center;
  padding-top: 0.75rem;
  font-size: 1.25rem;
  color: #000000;
`

export const ProgressBar = styled.div`
  box-sizing: content-box;
  height: 1.25rem; /* Can be anything */
  position: relative;
  border-radius: 25px;
  padding: 0.75rem;

  > span {
    display: block;
    height: 100%;
    border-radius: 20px;
    background-color: #304ffc;
    background-image: linear-gradient(
      center bottom,
      rgb(43, 194, 83) 37%,
      rgb(84, 240, 84) 69%
    );
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      z-index: 1;
      background-size: 3rem;
      animation: move 2s linear infinite;
      border-radius: 20px;
      overflow: hidden;
    }
  }
  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 3rem 3rem;
    }
  }
`
