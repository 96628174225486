import { useLocation } from 'react-router-dom'

import ArrowIcon from 'assets/icons/ArrowIcon'
import LogoPng from 'assets/images/logo_small.png'
import Supports from 'components/AppHeader/Supports'
import useAppHistory from 'hooks/useAppHistory'

import {
  ActionBtn,
  HeaderWrapper,
  LeftContent,
  Logo,
  MidContent,
  RightContent,
} from './styled'

const AppHeader = () => {
  const history = useAppHistory()

  const route = useLocation()

  const isHome = route?.pathname === '/'
  const isWelcome = route?.pathname?.includes('/welcome')
  const isSupportOn = false

  const goBack = () => {
    history.goBack()
  }

  if (isHome) {
    return null
  }

  return (
    <HeaderWrapper boxshadow={!isWelcome}>
      <LeftContent>
        {!isWelcome && (
          <ActionBtn onClick={goBack}>
            <ArrowIcon />
          </ActionBtn>
        )}
      </LeftContent>
      <MidContent>
        {!isWelcome && (
          <Logo
            alt="Virtual Evaluator"
            onClick={() => history.push('home')}
            role="presentation"
            src={LogoPng}
          />
        )}
      </MidContent>
      <RightContent>{isSupportOn && <Supports />}</RightContent>
    </HeaderWrapper>
  )
}

export default AppHeader
