import styled from 'styled-components'

export const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  background: #a3a3a3;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  right: -0.75rem;
  top: -0.75rem;
`

export const PlayerWrapper = styled.div`
  position: relative;
`
