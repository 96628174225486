import { useContext, useState } from 'react'
import { round } from 'lodash'

import AppFooter from 'components/AppFooter'
import Spinner from 'components/Spinner'
import Steps from 'components/Steps'
import {
  ExtraFooterLink,
  PageDescription,
  PageTitle,
} from 'components/common/styled'
import ProgressModal from 'components/modals/ProgressModal'
import ScanVinModal from 'components/modals/ScanVinModal'
import { AppContext } from 'context/AppContext'
import { uploadFileApi } from 'context/services/appServices'
import useAppHistory from 'hooks/useAppHistory'
import { formatAssetUrl } from 'utils/format'

import { TabInner } from './styled'

const IdentifyVehicle = ({ onChangeMode }) => {
  const history = useAppHistory()

  const { dispatch, state, types } = useContext(AppContext)

  const [openScan, setOpenScan] = useState(false)
  const [percent, setPercent] = useState(0)
  const [uploading, setUploading] = useState(true)

  const closeScanModal = () => {
    setOpenScan(false)
  }

  const handleChangeMode = () => {
    onChangeMode('manual')
  }

  const handleScreenshot = async (fileToUpload) => {
    try {
      setUploading(true)
      setOpenScan(false)
      const config = { onUploadProgress }
      // screen: "confirmodometer"
      const uploadtype = state.screen === 'requestodometer' ? 'Odometer' : 'VIN'
      const payload =
        state.screen === 'confirmodometersubmit'
          ? { code: 200, data: { slug: state.slug, miles: state.miles } }
          : { code: 200, data: { slug: state.slug, type: uploadtype } }
      const { data } = await uploadFileApi({
        config,
        data: payload,
        file: fileToUpload,
        path: state.callback,
      })

      switch (data.callback) {
        case 'vinretry': {
          if (state.screen !== 'retryVIN') {
            dispatch({
              type: types.RETRY_VIN_REQ,
            })
          }
          break
        }
        case 'requestodometer': {
          if (state.screen !== 'requestodometer') {
            dispatch({
              type: types.REQUEST_ODOMETER,
            })
          }
          break
        }
        case 'confirmodometer': {
          if (state.screen !== 'confirmodometer') {
            dispatch({
              type: types.CONFIRM_ODOMETER,
            })
          }
          break
        }
        case 'getclaim': {
          history.push('vehicle')
          break
        }
        default:
          break
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setUploading(false)
    }
  }

  const onUploadProgress = (evt) => {
    const { loaded, total } = evt
    const percentCompleted = round((loaded * 100) / total)
    setPercent(percentCompleted)
  }

  const openScanModal = () => {
    setOpenScan(true)
  }

  const btnText = state?.take?.on === 1 ? state?.take?.button : state.button

  if (!state.screen || state.loading) {
    return <Spinner />
  }

  return (
    <TabInner>
      <Steps />
      <PageTitle dangerouslySetInnerHTML={{ __html: state.title }} />
      <PageDescription dangerouslySetInnerHTML={{ __html: state.desc }} />
      <div className="confirm-vehicle-image">
        {state.img && <img alt="" src={formatAssetUrl(state.img)} />}
      </div>
      {state?.manual?.on === 1 && (
        <ExtraFooterLink
          className="verify-manually"
          onClick={handleChangeMode}
          role="presentation"
        >
          <span>{state.manual.button}</span>
        </ExtraFooterLink>
      )}
      {state.screen === 'confirmodometer' && (
        <ExtraFooterLink
          className="verify-manually"
          onClick={handleChangeMode}
          role="presentation"
        >
          <span>Type Manually</span>
        </ExtraFooterLink>
      )}
      <AppFooter onClick={openScanModal} text={btnText} />
      {uploading && <ProgressModal percent={percent} />}
      {openScan && (
        <ScanVinModal
          onCancel={closeScanModal}
          onChangeMode={handleChangeMode}
          onSubmit={handleScreenshot}
          screen={state.screen}
        />
      )}
    </TabInner>
  )
}

export default IdentifyVehicle
