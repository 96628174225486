import { useContext, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { round } from 'lodash'

import CameraIcon from 'assets/icons/CameraIcon'
import CloseIcon from 'assets/icons/CloseIcon'
import AppModal from 'components/AppModal'
import ProgressModal from 'components/modals/ProgressModal'
import Photos from 'components/Photos'
import { AppContext } from 'context/AppContext'
import { uploadFileApi } from 'context/services/appServices'
import { dataURL2File } from 'utils/format'

import {
  CloseButton,
  ModalBottom,
  ModalContent,
  ModalMid,
  ModalTop,
  RequiredDesc,
  RequiredTitle,
  TakePhoto,
} from './styled'

const videoConstraints = {
  facingMode: 'environment',
}

const TakePhotoModal = ({ photos, onClose }) => {
  const [selected, setSelected] = useState({})
  const [uploading, setUploading] = useState(true)
  const [percent, setPercent] = useState(0)
  const { state } = useContext(AppContext)
  const webcamRef = useRef()

  const handleSelectItem = (item) => {
    setSelected(item)
  }

  const onUploadProgress = (evt) => {
    const { loaded, total } = evt
    const percentCompleted = round((loaded * 100) / total)
    setPercent(percentCompleted)
  }

  const takePhoto = async () => {
    if (webcamRef.current) {
      const imgSrc = webcamRef.current.getScreenshot()
      const fileToUpload = await dataURL2File(imgSrc, new Date().toUTCString())

      if (fileToUpload) {
        try {
          setUploading(true)
          const payload = {
            code: 200,
            data: {
              UserID: 0,
              slug: state.slug,
              PhotoID: selected.id,
            },
          }
          const config = { onUploadProgress }
          await uploadFileApi({
            config,
            data: payload,
            file: fileToUpload,
            path: 'fileupload',
          })
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err)
        } finally {
          setUploading(false)
        }
      }
    }
  }

  useEffect(() => {
    if (photos) {
      setSelected(photos[0])
    }
  }, [])

  return (
    <AppModal>
      <ModalContent>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <ModalTop>
          <Photos
            borderColor="#ffffff"
            items={photos}
            noText
            onSelect={handleSelectItem}
            selected={selected}
            size="small"
          />
        </ModalTop>
        <ModalMid>
          <Webcam
            ref={webcamRef}
            style={{
              height: '100vh',
              width: '100%',
              objectFit: 'fill',
              position: 'absolute',
            }}
            videoConstraints={videoConstraints}
          />
        </ModalMid>
        <ModalBottom>
          <RequiredTitle>{selected.text}</RequiredTitle>
          <RequiredDesc>{selected.desc}</RequiredDesc>

          <TakePhoto onClick={takePhoto}>
            <CameraIcon />
          </TakePhoto>
        </ModalBottom>
        {uploading && <ProgressModal percent={percent} />}
      </ModalContent>
    </AppModal>
  )
}

export default TakePhotoModal
