import { useEffect, useState } from 'react'

import ArrowIcon from 'assets/icons/ArrowIcon'
import CloseIcon from 'assets/icons/CloseIcon'
import AppModal from 'components/AppModal'

import { formatAssetUrl } from 'utils/format'

import {
  ActionWrapper,
  CloseButton,
  LevelNavWrapper,
  LevelTab,
  ModalContent,
  ModalTitle,
  NextButton,
  OptionImage,
  OptionImageWrapper,
  ShortName,
} from './styled'

const DamageModal = ({ data, onClose, onSubmit, position }) => {
  const [selected, setSelected] = useState(null)

  const { option = [] } = data || {}

  const handleNext = () => {
    if (typeof onSubmit === 'function') {
      onSubmit(selected)
    }
  }

  const nextLevel = () => {
    const idx = option?.findIndex((op) => op.answer === selected?.answer)
    if (idx < option?.length - 1 && idx !== -1 && option?.[idx + 1]) {
      setSelected(option[idx + 1])
    }
  }

  const prevLevel = () => {
    const idx = option?.findIndex((op) => op.answer === selected?.answer)
    if (idx !== -1 && idx !== 0 && option?.[idx - 1]) {
      setSelected(option[idx - 1])
    }
  }

  useEffect(() => {
    if (!selected) {
      setSelected(option?.[0])
    }
  }, [option])

  return (
    <AppModal position={position}>
      <ModalContent>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <ModalTitle>Select Damage Severity</ModalTitle>
        <LevelNavWrapper>
          <div className="nav-wrapper">
            <div className="less" onClick={prevLevel} role="presentation">
              <ArrowIcon />
              <span>Less Damage</span>
            </div>
            <div className="more" onClick={nextLevel} role="presentation">
              <span>More Damage</span>
              <ArrowIcon />
            </div>
          </div>
          <div className="level-tabs">
            {option.map((op, i) => (
              <LevelTab
                idx={i}
                key={op.answer}
                onClick={() => setSelected(op)}
                selected={selected?.answer === op.answer}
              >
                {i + 1}
                {selected?.answer === op.answer && (
                  <ShortName>{op.shortName}</ShortName>
                )}
              </LevelTab>
            ))}
          </div>
        </LevelNavWrapper>
        <OptionImageWrapper>
          {selected && <OptionImage src={formatAssetUrl(selected.img)} />}
        </OptionImageWrapper>
        <ActionWrapper>
          <NextButton disabled={!selected} onClick={handleNext}>
            Next
          </NextButton>
        </ActionWrapper>
      </ModalContent>
    </AppModal>
  )
}

export default DamageModal
