import AppModal from 'components/AppModal'

import { Container, Percent, ProgressBar } from './styled'

const ProgressModal = ({ percent }) => {
  if (!percent) {
    return null
  }
  return (
    <AppModal>
      <Container>
        <ProgressBar>
          <span style={{ width: `${percent}%` }} />
        </ProgressBar>
        <Percent>{`${percent}%`}</Percent>
      </Container>
    </AppModal>
  )
}

export default ProgressModal
