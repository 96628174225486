import { createContext } from 'react'
import useReducer from './useReducer'
import middleware from './middleware'
import appReducer from './appReducer'
import * as types from './constants'

export const AppContext = createContext({})

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState, middleware)

  return (
    <AppContext.Provider value={{ dispatch, state, types }}>
      {children}
    </AppContext.Provider>
  )
}

export const initialState = {
  slug: '',
  years: {},
}

export default AppContext
