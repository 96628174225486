import styled from 'styled-components'

export const IdentifyVehicleContainer = styled.div`
  /* padding-top: 1.25rem; */
`

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const TabInner = styled.div`
  padding: ${({ noPadding }) => (noPadding ? '' : '0 1.5rem')};
  text-align: center;

  p {
    font-size: 1.25rem;
    color: #000000;
  }

  .confirm-vehicle-image {
    width: 100%;
    height: auto;
    margin-top: 2rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      mix-blend-mode: multiply;
    }
  }

  input[type='file'] {
    display: none;
  }

  .not-found-vin {
    padding-top: 1.25rem;

    p {
      cursor: pointer;
      color: #909090;
    }
  }

  .scan-with-camera,
  .verify-manually {
    padding: 2rem;
    color: #304ffc;
    font-size: 1.25rem;
    cursor: pointer;
    font-weight: 700;
    cursor: pointer;
  }
`
export const ManualInput = styled.input`
  font-size: 1.75rem;
  background: #ffffff;
  color: #304ffc;
  padding: 1.5rem 1rem;
  border: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
  width: 100%;

  &:focus {
    outline: none;
  }
`

export const RetryInner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .image-wrapper {
    width: 250px;
    height: 250px;

    img {
      height: 100%;
      width: 100%;
      object: contain;
    }
  }
`
