import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { AppContext } from 'context/AppContext'
import { ROUTES } from 'config/routes'

const useAppHistory = () => {
  const { state } = useContext(AppContext)
  const { push, ...rest } = useHistory()

  const pushPath = (pathKey, slugId, autoPartId) => {
    const autoPart = autoPartId || state.autoPartId
    const slug = slugId || state.slug
    const path = ROUTES[pathKey]

    if (slug && path) {
      const nextPath = path
        .replace(':slugId', slug)
        .replace(':autoPartId', autoPart)
      push(nextPath)
    }
  }

  return { ...rest, push: pushPath }
}

export default useAppHistory
