import ReactPlayer from 'react-player'

import CloseIcon from 'assets/icons/CloseIcon'
import AppModal from 'components/AppModal'
import { formatVideoUrl } from 'utils/format'

import { IconWrapper, PlayerWrapper } from './styled'

const VideoModal = ({ src, onClose }) => {
  return (
    <AppModal onClose={onClose}>
      <PlayerWrapper>
        <IconWrapper onClick={onClose}>
          <CloseIcon />
        </IconWrapper>
        <ReactPlayer
          controls
          height="550px"
          playing
          url={formatVideoUrl(src)}
          width="320px"
        />
      </PlayerWrapper>
    </AppModal>
  )
}

export default VideoModal
