import { useContext, useEffect, useState } from 'react'

import AppFooter from 'components/AppFooter'
import { PageTitle } from 'components/common/styled'
import ImageMap from 'components/ImageMap'
import AddMoreDamage from 'components/modals/AddMoreDamage'
import Spinner from 'components/Spinner'
import Steps from 'components/Steps'
import { AppContext } from 'context/AppContext'
import {
  getAutoPartDamageLocationApi,
  saveDamageLocationApi,
  saveSnapshot,
} from 'context/services/appServices'
import useAppHistory from 'hooks/useAppHistory'
import { formatAssetUrl } from 'utils/format'

import { toggleGrid } from './helpers'

import { Container, TopWrapper, CarWrapper } from './styled'

const DamageDetail = () => {
  const history = useAppHistory()

  const { dispatch, state, types } = useContext(AppContext)

  const [enableNext, setEnableNext] = useState(false)
  const [isOpenAddMore, setIsOpenAddMore] = useState(false)
  const [screenshot, setScreenshot] = useState(null)
  const [selected, setSelected] = useState([])
  const [showSpin, setShowSpin] = useState(false)

  const fetchMapData = async () => {
    const payload = {
      code: 200,
      data: { slug: state.slug, autoPartID: state.autoPartId },
    }

    setShowSpin(true)

    try {
      const { data } = await getAutoPartDamageLocationApi(payload)

      mapData = Object.keys(data.map).map((key) => data.map[key])

      dispatch({
        payload: data,
        type: types.SET_PAGE_DATE,
      })

      setShowSpin(false)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)

      setShowSpin(false)
    }
  }

  const nextStep = async () => {
    await saveScreenshot()
    await saveDamageGrid()

    setIsOpenAddMore(true)
  }

  const onSelectPart = (grid) => {
    const selectedGrids = toggleGrid(selected, grid)

    setSelected(selectedGrids)
    setEnableNext(selectedGrids.length > 0)
  }

  const saveScreenshot = async () => {
    const payload = {
      code: 200,
      data: {
        img: screenshot,
        slug: state.slug,
      },
    }

    setShowSpin(true)

    try {
      await saveSnapshot(payload)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setShowSpin(false)
    }
  }

  const saveDamageGrid = async () => {
    const payload = {
      code: 200,
      data: {
        autoPartID: state.autoPartId,
        damageLocation: selected,
        slug: state.slug,
      },
    }

    setShowSpin(true)

    try {
      await saveDamageLocationApi(payload)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setShowSpin(false)
    }
  }

  let mapData = state.map
    ? Object.keys(state.map).map((key) => state.map[key])
    : undefined

  useEffect(() => {
    if (state.slug && !mapData) {
      if (state.autoPartId) {
        fetchMapData()
      } else {
        history.push('damage')
      }
    }
  }, [state.autoPartId, state.slug, mapData])

  return (
    <Container>
      <TopWrapper>
        <Steps />
        <PageTitle>Select the Damaged Area</PageTitle>
      </TopWrapper>

      {mapData && (
        <CarWrapper>
          <ImageMap
            getScreenshot={setScreenshot}
            height={state.height}
            imageSrc={formatAssetUrl(state.img)}
            isGrid
            mapData={mapData}
            onSelectPart={onSelectPart}
            selected={selected}
            width={state.width}
          />
        </CarWrapper>
      )}

      <AppFooter disabled={!enableNext} onClick={nextStep} text="Next" />

      {showSpin && <Spinner />}

      {isOpenAddMore && (
        <AddMoreDamage
          onAddMore={() => history.push('damage')}
          onNoAddMore={() => history.push('requirephotos')}
          position="bottom"
        />
      )}
    </Container>
  )
}

export default DamageDetail
