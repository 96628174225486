import { useContext, useMemo } from 'react'

import { AppContext } from 'context/AppContext'

import { Container, DotTagDiv } from './styled'

const Steps = ({ fakeSteps }) => {
  const { state } = useContext(AppContext)

  const [steps, current] = useMemo(() => {
    if (fakeSteps) {
      return [fakeSteps, fakeSteps]
    }

    return [state?.steps?.totalStep, state?.steps?.currentStep]
  }, [fakeSteps, state.steps])

  if (!steps) {
    return null
  }

  return (
    <Container>
      <ul>
        {new Array(steps || 1).fill(true).map((_, i) => (
          <li key={i}>
            <DotTagDiv
              current={i + 1 === current}
              completed={i + 1 < current}
            />
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default Steps
