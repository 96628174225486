import AppModal from 'components/AppModal'
import { PageDescription } from 'components/common/styled'
import { formatAssetUrl } from 'utils/format'

import {
  Container,
  Image,
  ImageWrapper,
  ModalFooter,
  ModalTitle,
} from './styled'

const PopupIntroModal = ({ data, onClose }) => {
  return (
    <AppModal onClose={onClose}>
      <Container>
        <ModalTitle>{data.title}</ModalTitle>
        <PageDescription>{data.description}</PageDescription>
        {data.image && (
          <ImageWrapper>
            <Image src={formatAssetUrl(data.image)} />
          </ImageWrapper>
        )}
        {data?.button?.on === 1 && (
          <ModalFooter color={data.button.color} onClick={onClose}>
            {data.button.button}
          </ModalFooter>
        )}
      </Container>
    </AppModal>
  )
}

export default PopupIntroModal
