import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const TopWrapper = styled.div`
  padding: 0 1.25rem;
  border-bottom: 1px solid #e6e6e6;
`

export const Content = styled.div`
  padding: 0 1.5rem;
`

export const InputWrapper = styled.div`
  margin-bottom: 3rem;
`

export const FindVehicle = styled.span`
  font-size: 0.75rem;
  color: #304ffc;
  cursor: pointer;
`

export const SelectFormContainer = styled.div`
  text-align: center;
`

export const FieldRow = styled.div`
  position: relative;
  padding: 0.75rem 0;
`
