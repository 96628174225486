import styled from 'styled-components'

export const FooterContainer = styled.div`
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ disabled }) => (disabled ? '#f8f8f8' : '#304ffc')};
  color: ${({ disabled }) => (disabled ? '#c6c6c6' : '#ffffff')};

  > div {
    font-size: 1.5rem;
    font-weight: 700;
    flex: 1;
    text-align: center;
  }

  .icon-wrapper {
    position: absolute;
    right: 0;
    svg {
      transform: rotate(180deg);
      margin-right: 2rem;
    }
  }
`

export const OuterWrapper = styled.div`
  min-height: 4.5rem;
  position: fixed;
  bottom: 0;
  left: 50%;
  max-width: 500px;
  width: 100%;
  transform: translateX(-50%);
  user-select: none;
  z-index: 1000;

  .switch-to-camera {
    padding: 2rem;
    color: #304ffc;
    font-size: 1.5rem;
    cursor: pointer;
    font-weight: 700;
    cursor: pointer;
  }
`
