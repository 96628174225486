import styled from 'styled-components'

import { Card } from 'components/common/styled'

export const Container = styled(Card)`
  width: 80%;
  height: 80%;
  position: relative;
`

export const FieldRow = styled.div`
  position: relative;
  padding: 0.75rem 0;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  background: #a3a3a3;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  right: -0.75rem;
  top: -0.75rem;
`

export const NextButton = styled.div`
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  font-size: 1.25rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ disabled }) => (disabled ? '#f8f8f8' : '#304ffc')};
  color: ${({ disabled }) => (disabled ? '#c6c6c6' : '#ffffff')};
`
