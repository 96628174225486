import { useEffect, useRef } from 'react'

import { getIsIncludedGrid } from 'components/common/helpers'

import {
  drawPolygon,
  drawPolygonsClipped,
  drawTouchPoint,
  getCoordinatePairsFromPolygon,
  getCoordinatesFromPolygon,
  getGridByCoordinates,
  getIsOverlapping,
  getIsPointInPolygons,
  getRectangleFromPolygon,
} from './helpers'

import { Canvas, CanvasWrapper, CarImage, Container } from './styled'

const ImageMap = ({
  getScreenshot = () => {},
  height,
  imageSrc,
  isGrid = false,
  mapData,
  onSelectPart,
  padding,
  selected = [],
  width,
}) => {
  const canvasRef = useRef(null)

  const targetRadius = 20
  const isScaled = width > 450
  const scale = isScaled ? 450 / width : 1
  const scaleHeight = isScaled ? height * scale : height
  const scaleWidth = isScaled ? 450 : width

  const addAnchor = (anchor) => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    const coordinatesScaled = getCoordinatesFromPolygon(
      anchor.Coordinates,
      scale
    )

    if (isGrid) {
      anchor.child?.forEach((gridPolygon) => {
        const gridPolygonFormatted = {
          id: gridPolygon.Answer,
        }

        const active = getIsIncludedGrid(selected, gridPolygonFormatted)
        const gridCoordinatesScaled = getCoordinatesFromPolygon(
          gridPolygon.Coordinates,
          scale
        )

        drawPolygon(context, gridCoordinatesScaled, active)
      })

      /*
      // Draw intersects for testing purposes only
      anchor.sub?.forEach((gridPolygon) => {
        const gridCoordinatesScaled = getCoordinatesFromPolygon(
          gridPolygon.Coordinates,
          scale
        ).join(',')

        const rectangleScaled = getRectangleFromPolygon(gridCoordinatesScaled)

        drawPolygon(context, rectangleScaled, false)
      })
      */
    } else {
      drawTouchPoint(context, coordinatesScaled)
    }
  }

  const onClickCanvas = (evt) => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    const rect = canvas.getBoundingClientRect()
    const clickCoordinates = [evt.clientX - rect.left, evt.clientY - rect.top]

    const coordinateLists = mapData.map((coordinateList) =>
      getCoordinatePairsFromPolygon(coordinateList.Coordinates, scale)
    )

    const isPointInPolygon = getIsPointInPolygons(
      context,
      coordinateLists,
      clickCoordinates
    )

    if (isPointInPolygon) {
      const selectedPolygon = mapData.find((anchor) => {
        const anchorCoordinateLists = [
          getCoordinatePairsFromPolygon(anchor.Coordinates, scale),
        ]

        const isPointInAnchor = getIsPointInPolygons(
          context,
          anchorCoordinateLists,
          clickCoordinates
        )

        return isPointInAnchor
      })

      if (isGrid) {
        if (selectedPolygon.child) {
          const grid = getGridByCoordinates(
            selectedPolygon.child,
            clickCoordinates,
            scale
          )

          const selectedGrid = {
            id: grid.Answer,
            intersects: [],
          }

          selectedPolygon.sub?.forEach((gridPolygon) => {
            const gridPolygonScaled = getCoordinatesFromPolygon(
              gridPolygon.Coordinates,
              scale
            ).join(',')

            const rectangleScaled =
              getRectangleFromPolygon(gridPolygonScaled).join(',')

            const isOverlapping = getIsOverlapping(
              grid.Coordinates,
              rectangleScaled
            )

            if (isOverlapping) {
              selectedGrid.intersects.push(gridPolygon.id)
            }
          })

          onSelectPart(selectedGrid)
        }
      } else {
        onSelectPart(selectedPolygon)
      }
    }
  }

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')

      context.clearRect(0, 0, canvas.width, canvas.height)

      if (isGrid) {
        const coordinateLists = mapData?.map(
          (anchor) =>
            anchor.child && getCoordinatesFromPolygon(anchor.Coordinates, scale)
        )

        drawPolygonsClipped(context, coordinateLists)
      }

      mapData?.forEach((anchor) => addAnchor(anchor))
    }
  }, [mapData])

  // Canvas screenshot proof of concept
  useEffect(() => {
    const canvas = canvasRef.current
    const screenshot = canvas.toDataURL('image/jpeg')

    getScreenshot(screenshot)
  }, [selected])

  return (
    <Container padding={padding}>
      <CanvasWrapper canvasH={scaleHeight} canvasW={scaleWidth}>
        <CarImage src={imageSrc} />
        <Canvas
          height={scaleHeight + targetRadius}
          onClick={onClickCanvas}
          ref={canvasRef}
          width={scaleWidth}
        />
      </CanvasWrapper>
    </Container>
  )
}

export default ImageMap
