import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const TopWrapper = styled.div`
  padding: 0 1.25rem;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 2rem;

  .page-desc {
    padding: 0 3rem;
    text-align: center;
  }
`

export const Content = styled.div`
  flex: 1;
  padding: 2rem 2rem;
  background: #ffffff;
`
