const CameraIcon = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path d="M15.6429 5C14.2537 5 13.0103 5.86142 12.5228 7.16309L11.4583 10H6.66667C4.36583 10 2.5 11.8658 2.5 14.1667V30C2.5 32.3008 4.36583 34.1667 6.66667 34.1667H32.5C34.8008 34.1667 36.6667 32.3008 36.6667 30V14.1667C36.6667 11.8658 34.8008 10 32.5 10H28.5417L27.4772 7.16309C26.9897 5.86142 25.7463 5 24.3571 5H15.6429ZM20 14.1667C24.365 14.1667 27.9167 17.7183 27.9167 22.0833C27.9167 26.4483 24.365 30 20 30C15.635 30 12.0833 26.4483 12.0833 22.0833C12.0833 17.7183 15.635 14.1667 20 14.1667ZM20 16.6667C17.0133 16.6667 14.5833 19.0967 14.5833 22.0833C14.5833 25.07 17.0133 27.5 20 27.5C22.9867 27.5 25.4167 25.07 25.4167 22.0833C25.4167 19.0967 22.9867 16.6667 20 16.6667Z" />
    </svg>
  )
}

export default CameraIcon
