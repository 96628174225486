import styled from 'styled-components'

export const CloseButton = styled.div`
  color: #ffffff;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 1000;
`

export const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  background: #ffffff;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .top-wrapper,
  .bottom-wrapper {
    height: 280px;
    width: 100%;
    position: absolute;
    z-index: 10;
  }

  .top-wrapper {
    top: 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);

    .top-inner {
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      h3 {
        margin-top: 2rem;
      }

      p {
        margin: 0;
        max-width: 250px;
        text-align: center;
        font-size: 1.25rem;
      }
    }
  }

  .bottom-wrapper {
    bottom: 0;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(180deg);

    .bottom-inner {
      transform: rotate(180deg);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #ffffff;

      p {
        text-align: center;
        margin-bottom: 1rem;
      }

      .take-photo-btn {
        height: 70px;
        width: 170px;
        border: 1px solid #dadada;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 2rem;
          height: 2rem;
          margin-right: 0.75rem;
        }

        span {
          margin-top: 0.25rem;
        }
      }
    }
  }
`
