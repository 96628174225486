import { useContext, useMemo, useState } from 'react'
import Select from 'react-select'

import CloseIcon from 'assets/icons/CloseIcon'
import AppModal from 'components/AppModal'
import { SubTitle } from 'components/common/styled'
import Spinner from 'components/Spinner'
import { AppContext } from 'context/AppContext'
import {
  getMakesFromDataApi,
  getModelsFromDataApi,
  getStylesFromDataApi,
  saveVehicleApi,
} from 'context/services/appServices'

import { Container, FieldRow, IconWrapper, NextButton } from './styled'

const FindVehicleModal = ({ onClose }) => {
  const { dispatch, state, types } = useContext(AppContext)

  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState(null)
  const [make, setMake] = useState(null)
  const [makeItems, setMakeItems] = useState([])
  const [model, setModel] = useState(null)
  const [modelItems, setModelItems] = useState([])
  const [showSpin, setShowSpin] = useState(false)
  const [year, setYear] = useState(null)

  const [yOts] = useMemo(() => {
    const yKeys = Object.keys(state.years)
      .sort((a, b) => b - a)
      .map((k) => ({
        value: state.years[k],
        label: k,
      }))

    return [yKeys]
  }, [state.years])

  const onChangeCategory = (evt) => {
    setCategory(evt)
  }

  const onChangeMake = async (evt) => {
    try {
      setMake(evt)
      setShowSpin(true)
      setModel(null)
      setCategory(null)
      const params = { year: year.value, make: evt.value }
      const { data } = await getModelsFromDataApi(params)
      setModelItems(data.map((ite) => ({ label: ite.model, value: ite.model })))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setShowSpin(false)
    }
  }

  const onChangeModel = async (evt) => {
    try {
      setModel(evt)
      setShowSpin(true)
      setCategory(null)
      const params = { year: year.value, make: make.value, model: evt.value }
      const { data } = await getStylesFromDataApi(params)

      const items = data.map((ite) => ({
        label: ite.style,
        value: ite.vehicleId,
      }))
      setCategories(items)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setShowSpin(false)
    }
  }

  const onChangeYear = async (evt) => {
    try {
      setYear(evt)
      setMake(null)
      setModel(null)
      setCategory(null)
      setShowSpin(true)
      const { data } = await getMakesFromDataApi({ year: evt.value })
      setMakeItems(data.map((ite) => ({ label: ite.make, value: ite.make })))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setShowSpin(false)
    }
  }

  const onSubmit = async () => {
    try {
      setShowSpin(true)

      const payload = {
        code: 200,
        data: {
          AutoYear: year.value,
          AutoID: category.value,
          slug: state.slug,
        },
      }

      const { data } = await saveVehicleApi(payload)
      if (data.callback === 'requestodometer') {
        dispatch({
          type: types.REQUEST_ODOMETER,
        })
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      onClose(null, true)
    }
  }

  const valid = useMemo(
    () => !!year && !!make && !!model && !!category,
    [year, make, model, category]
  )

  return (
    <AppModal>
      <Container>
        <IconWrapper onClick={onClose}>
          <CloseIcon />
        </IconWrapper>
        <SubTitle>Select your vehicle:</SubTitle>
        <FieldRow>
          <Select
            options={yOts}
            name="year"
            value={year}
            onChange={onChangeYear}
            placeholder="Select Year..."
          />
        </FieldRow>
        <FieldRow>
          <Select
            name="make"
            value={make}
            options={makeItems}
            placeholder="Make"
            onChange={onChangeMake}
          />
        </FieldRow>
        <FieldRow>
          <Select
            name="model"
            value={model}
            placeholder="Model"
            options={modelItems}
            onChange={onChangeModel}
          />
        </FieldRow>
        <FieldRow>
          <Select
            name="category"
            value={category}
            options={categories}
            placeholder="Category"
            onChange={onChangeCategory}
          />
        </FieldRow>
        <NextButton disabled={!valid} onClick={onSubmit}>
          Next
        </NextButton>
        {showSpin && <Spinner />}
      </Container>
    </AppModal>
  )
}

export default FindVehicleModal
