import CloseIcon from 'assets/icons/CloseIcon'
import vinimg from 'assets/images/vin.png'
import AppModal from 'components/AppModal'
import { SubTitle } from 'components/common/styled'

import { IconWrapper, ImageView, ModalContent } from './styled'

const ExModal = ({ onClose }) => {
  return (
    <AppModal>
      <ModalContent>
        <IconWrapper onClick={onClose}>
          <CloseIcon />
        </IconWrapper>
        <SubTitle>Vin Locations</SubTitle>
        <ImageView src={vinimg} />
      </ModalContent>
    </AppModal>
  )
}

export default ExModal
