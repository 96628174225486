import { useContext, useEffect } from 'react'

import AppFooter from 'components/AppFooter'
import { Card, PageTitle, ExtraFooterLink } from 'components/common/styled'
import Spinner from 'components/Spinner'
import Steps from 'components/Steps'
import { AppContext } from 'context/AppContext'
import useAppHistory from 'hooks/useAppHistory'

import { InnerContainer, PropertyRow, VehicleContainer } from './styled'

const Vehicle = () => {
  const history = useAppHistory()

  const { dispatch, state, types } = useContext(AppContext)

  useEffect(() => {
    if (state.slug) {
      dispatch({
        type: types.FETCH_CLAIM,
      })
    }
  }, [state.slug])

  const onClickConfirm = () => {
    history.push('damage')
  }

  const onClickGetVehicle = () => {
    history.push('getvehicle')
  }

  if (!state.screen || state.loading) {
    return <Spinner />
  }

  return (
    <VehicleContainer>
      <Steps />
      <PageTitle>Is this your vehicle?</PageTitle>
      <InnerContainer>
        <Card>
          <PropertyRow>
            <legend>VIN</legend>
            <p>{state.AutoVIN}</p>
          </PropertyRow>
          <PropertyRow>
            <legend>Make</legend>
            <p>{state.Make}</p>
          </PropertyRow>
          <PropertyRow>
            <legend>Model</legend>
            <p>{state.Model}</p>
          </PropertyRow>
          <PropertyRow>
            <legend>Year</legend>
            <p>{state.AutoYear}</p>
          </PropertyRow>
          <PropertyRow>
            <legend>Trim</legend>
            <p>{state.Trim}</p>
          </PropertyRow>
          <PropertyRow>
            <legend>Miles</legend>
            <p>{state.AutoMileage || 0}</p>
          </PropertyRow>
        </Card>
      </InnerContainer>
      <ExtraFooterLink onClick={onClickGetVehicle}>
        No, it is not my car.
      </ExtraFooterLink>
      <AppFooter onClick={onClickConfirm} text="Confirm" />
    </VehicleContainer>
  )
}

export default Vehicle
