import { useContext, useMemo, useState } from 'react'

import AppFooter from 'components/AppFooter'
import {
  PageTitle,
  PageDescription,
  ExtraFooterLink,
} from 'components/common/styled'
import FindVehicleModal from 'components/modals/FindVehicleModal'
import Spinner from 'components/Spinner'
import Steps from 'components/Steps'
import { AppContext } from 'context/AppContext'
import {
  confirmOdometerSubmitApi,
  saveVehicleApi,
} from 'context/services/appServices'
import useAppHistory from 'hooks/useAppHistory'

import { TabInner, ManualInput } from './styled'

const ManualConfirm = ({ onChangeMode }) => {
  const history = useAppHistory()

  const { dispatch, state, types } = useContext(AppContext)

  const [showFindVehicle, setShowFindVehicle] = useState(false)
  const [showSpin, setShowSpin] = useState(false)
  const [value, setValue] = useState('')

  const valid = useMemo(() => {
    if (state.screen === 'requestVIN') {
      return value.length === 17
    }
    return value?.length > 0
  }, [value, state.screen])

  const changeVin = (evt) => {
    if (!valid || evt.target.value.length < 17) {
      setValue((evt.target.value || '').replace(/\s/g, ''))
    }
  }

  const handleChangeMode = () => {
    onChangeMode('scan')
  }

  const onSubmit = () => {
    if (state.screen === 'requestVIN' || state.screen === 'retryVIN') {
      saveVehicle()
    } else if (state.screen === 'requestodometer') {
      submitOdometer()
    }
  }

  const saveVehicle = async () => {
    try {
      const payload = {
        code: 200,
        data: {
          AutoVIN: value,
          slug: state.slug,
        },
      }
      setShowSpin(true)
      const { data } = await saveVehicleApi(payload)
      if (data.callback === 'requestodometer') {
        dispatch({
          type: types.REQUEST_ODOMETER,
        })
      }
      handleChangeMode()
      // eslint-disable-next-line
    } catch (error) {
    } finally {
      setShowSpin(false)
    }
  }

  const submitOdometer = async () => {
    try {
      const payload = {
        code: 200,
        data: {
          miles: value,
          slug: state.slug,
        },
      }
      setShowSpin(true)
      const { data } = await confirmOdometerSubmitApi(payload)
      if (data.callback === 'getclaim') {
        history.push('vehicle')
      }
      // eslint-disable-next-line
    } catch (error) {
      setShowSpin(false)
    }
  }

  const toggleModal = (evt, goToScan) => {
    if (goToScan) {
      onChangeMode('scan')
    } else {
      setShowFindVehicle(!showFindVehicle)
    }
  }

  const desc = useMemo(() => {
    if (state.screen === 'confirmodometer') {
      return state.message
    }
    return 'Please type 17 character number below'
  }, [state.screen])

  return (
    <TabInner noPadding>
      <Steps />
      <PageTitle
        dangerouslySetInnerHTML={{
          __html: `Confirm Your Vehicle By <br /> ${
            state.screen === 'requestVIN' ? 'VIN' : 'Odometer'
          } Number`,
        }}
      />
      <PageDescription>{desc}</PageDescription>
      <ManualInput
        onChange={changeVin}
        placeholder="xxxxxxxxxxxxxxxx"
        value={value}
      />
      {state?.find?.on === 1 && (
        <div
          className="not-found-value"
          onClick={toggleModal}
          role="presentation"
        >
          <p>{state.find.button}</p>
        </div>
      )}
      {state?.manual?.on === 1 && (
        <ExtraFooterLink
          className="scan-with-camera"
          onClick={handleChangeMode}
          role="presentation"
        >
          <span>
            {state.screen === 'confirmodometer'
              ? 'Retake Picture'
              : 'Take Picture'}
          </span>
        </ExtraFooterLink>
      )}
      <AppFooter disabled={!valid} onClick={onSubmit} text="Next" />
      {showFindVehicle && <FindVehicleModal onClose={toggleModal} />}
      {showSpin && <Spinner />}
    </TabInner>
  )
}

export default ManualConfirm
