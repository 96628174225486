import styled from 'styled-components'

export const ImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  position: relative;
  z-index: 10;
`

export const ImageWrapper = styled.div`
  height: ${({ size }) => (size === 'small' ? '4.5rem' : '95px')};
  width: ${({ size }) => (size === 'small' ? '4.5rem' : '95px')};
  padding: 0.75rem;
  border: 1px solid;
  border-color: ${({ borderColor }) => borderColor || '#c6c6c6'};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ noMargin }) => (!noMargin ? '0.75rem' : '')};
  cursor: pointer;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`

export const RequireItem = styled.div`
  background: ${({ selected }) => selected && '#304ffc'};
  border-radius: 8px;

  .image-name {
    font-size: 0.75rem;
    text-align: center;
    color: #666666;
  }
`
