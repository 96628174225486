import { getIsIncludedGrid } from 'components/common/helpers'

export const toggleGrid = (grids, grid) => {
  const isGridIncluded = getIsIncludedGrid(grids, grid)
  let gridsActive = []

  if (isGridIncluded) {
    gridsActive = grids.filter((gridActive) => {
      return gridActive.id !== grid.id
    })
  } else {
    gridsActive = [...grids, grid]
  }

  return gridsActive
}
