import styled from 'styled-components'

export const ActionWrapper = styled.div`
  padding: 1.25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const COLORS = ['#ffb800', '#ff9900', '#ff6b00', '#ff4c00', '#ff1f00']

export const CloseButton = styled.div`
  color: #666666;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`

export const OptionImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`

export const OptionImageWrapper = styled.div`
  padding-top: 1.5rem;
`

export const LevelNavWrapper = styled.div`
  padding: 0 1.5rem;

  .nav-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .less,
    .more {
      display: flex;
      align-items: center;
      font-size: 1rem;
      user-select: none;
      cursor: pointer;

      span {
        padding: 0 0.75rem;
      }
    }

    .less {
      color: #ff9900;
      justify-content: flex-start;
    }

    .more {
      color: #ff1f00;
      justify-content: flex-end;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  .level-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
`

export const LevelTab = styled.div`
  width: 4rem;
  height: 4rem;
  border: 0.25rem solid;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  border-color: ${({ idx }) => COLORS[idx]};
  background: ${({ selected, idx }) => (selected ? COLORS[idx] : '#ffffff')};
  color: ${({ selected, idx }) => (selected ? '#ffffff' : COLORS[idx])};
  cursor: pointer;
`

export const ModalContent = styled.div`
  background: white;
  width: 100%;
  min-height: 100vh;
  max-height: 100%;
  overflow: auto;
  position: relative;
`

export const ModalTitle = styled.h4`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
`

export const NextButton = styled.div`
  border-radius: 30px;
  border: 1px solid;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1rem;
  text-align: center;
  user-select: none;
  width: 200px;
  background-color: ${({ disabled }) => (disabled ? '#ffffff' : '#304ffc')};
  color: ${({ disabled }) => (disabled ? '#00000099' : '#ffffff')};
`

export const ShortName = styled.div`
  font-size: 1.25rem;
  text-align: center;
  color: #000000cc;
  position: absolute;
  bottom: -100%;
`
