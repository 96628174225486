import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding || '1.75rem 0'};
`

export const CanvasWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${({ canvasW }) => `${canvasW}px` || '300px'};
  height: ${({ canvasH }) => `${canvasH}px` || '570px'};
`

export const CarImage = styled.img`
  width: 100%;
  height: 100%;
`

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  z-index: 1000;
  cursor: pointer;
`
