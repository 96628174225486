import { API_URL } from '../config'

export const formatImageUrl = (url = '') => {
  if (url.includes('static/media/') || !url.includes('claimfiles')) {
    return url
  }
  return `${API_URL}${url}`
}

export const formatAssetUrl = (url = '') => {
  // Only prepend absolute path if not already present.
  return url.includes(API_URL) ? url : `${API_URL}${url}`
}

export const formatVideoUrl = (url) => {
  return `https://new-api.virtualevaluator.net${url}`
}

export const dataURL2File = async (dataurl, filename) => {
  // eslint-disable-next-line
  var arr = dataurl.split(',')
  // eslint-disable-next-line
  var mime = arr[0].match(/:(.*?);/)[1]
  // eslint-disable-next-line
  var bstr = atob(arr[1])
  // eslint-disable-next-line
  var n = bstr.length
  // eslint-disable-next-line
  var u8arr = new Uint8Array(n)

  // eslint-disable-next-line
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const getScriptsFromStr = (str) => {
  str.match(/<script\b[^>]*>(.*?)<\/script>/gm)?.forEach((script) => {
    if (script) {
      const matchs = script.match(/src="(.*?)"/)
      if (matchs && matchs[1]) {
        const source = matchs[1]
        let scriptTag = document.querySelector(`script[src="${source}"]`)
        if (!scriptTag) {
          scriptTag = document.createElement('script')
          scriptTag.async = true
          scriptTag.src = source
          document.head.appendChild(scriptTag)
        }
      }
    }
  })

  const sanitized = str.replace(/<script\b[^>]*>(.*?)<\/script>/gm, '')
  // .replace(/<hr \/>/g, '')
  return sanitized
}
