import axios from 'axios'

import { API_URL } from 'config'

class BaseApi {
  constructor(baseURL = API_URL) {
    this.baseURL = baseURL
  }

  async get(endpoint, params) {
    return axios({
      params,
      baseURL: this.baseURL,
      url: endpoint,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then((response) => response.data)
  }

  async post(endpoint, data) {
    return axios({
      baseURL: this.baseURL,
      url: endpoint,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: `data=${JSON.stringify(data)}`,
    }).then((response) => response.data)
  }

  async upload(endpoint, data, file, config = {}) {
    const form = new FormData()
    form.append('file', file)
    form.append('data', JSON.stringify(data))

    return axios({
      baseURL: this.baseURL,
      url: endpoint,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data; ',
      },
      data: form,
      ...config,
    }).then((response) => response.data)
  }
}

export default BaseApi
