import { useContext, useState } from 'react'

import FlagIcon from 'assets/icons/FlagIcon'
import {
  Button,
  PageDescription,
  PageTitle,
  SubTitle,
} from 'components/common/styled'
import Spinner from 'components/Spinner'
import { AppContext } from 'context/AppContext'
import { getClaimApi } from 'context/services/appServices'
import useAppHistory from 'hooks/useAppHistory'

import {
  BottomContent,
  ErrorMessage,
  HomeContainer,
  IconWrapper,
  SlugInput,
  TopContent,
} from './styled'

const Home = () => {
  const history = useAppHistory()

  const { dispatch, types } = useContext(AppContext)

  const [errMsg, setErrorMsg] = useState('')
  const [process, setProcess] = useState(false)
  const [vin, setVin] = useState('318045')

  const handleChange = (evt) => {
    setVin(evt.target.value)
  }

  const handleNext = async () => {
    try {
      setProcess(true)
      const postData = {
        code: 200,
        data: {
          profile_slug: '',
          Customer_Zip: 91701,
          ClaimID: vin,
        },
      }
      const res = await getClaimApi(postData)
      if (res.code === 205) {
        // TODO: permistion issue??
        dispatch({
          type: types.SET_SLUG_ID,
          payload: res.data.slug,
        })
        setErrorMsg('res.data.message')
      } else {
        dispatch({
          type: types.SET_SLUG_ID,
          payload: res.data.slug,
        })
        history.push('welcome', res.data.slug)
      }
    } catch (error) {
      setProcess(false)
      // eslint-disable-next-line no-console
      console.error('Unable to fetch brands', error)
    }
  }

  return (
    <HomeContainer>
      {process && <Spinner />}
      <TopContent>
        <PageTitle mb="0.5rem">Vehicle Incident Report</PageTitle>
        <small>v2.4</small>
      </TopContent>
      <IconWrapper>
        <FlagIcon />
      </IconWrapper>
      <BottomContent>
        <SubTitle>Enter your Claim # or VIN</SubTitle>
        <PageDescription>
          You will be asked to provide photos and some basic information about
          the incident.
        </PageDescription>
        <SlugInput onChange={handleChange} value={vin} />
        {errMsg && <ErrorMessage>{errMsg}</ErrorMessage>}
        <Button disabled={!vin || process} onClick={handleNext}>
          Next
        </Button>
      </BottomContent>
    </HomeContainer>
  )
}

export default Home
