import styled from 'styled-components'

import { Card } from 'components/common/styled'

export const IconWrapper = styled.div`
  color: #c6c6c6;
  position: absolute;
  right: 1rem;
  top: 1rem;
`

export const ImageView = styled.img`
  width: 100%;
`

export const ModalContent = styled(Card)`
  width: 85%;
  position: relative;
  text-align: center;
`
