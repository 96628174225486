import ArrowIcon from 'assets/icons/ArrowIcon'

import { FooterContainer, OuterWrapper } from './styled'

const AppFooter = ({ disabled, onClick, text }) => {
  const handleClick = (evt) => {
    if (!disabled && typeof onClick === 'function') {
      onClick(evt)
    }
  }

  return (
    <OuterWrapper>
      <FooterContainer
        disabled={disabled}
        id="footer-container"
        onClick={handleClick}
      >
        <div>{text}</div>
        <span className="icon-wrapper">
          <ArrowIcon />
        </span>
      </FooterContainer>
    </OuterWrapper>
  )
}

export default AppFooter
