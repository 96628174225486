import styled from 'styled-components'
import { Card } from 'components/common/styled'

export const HomeContainer = styled(Card)`
  width: 80%;
  margin: auto;
  min-height: 380px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`

export const TopContent = styled.div`
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;

  small {
    color: #999999;
    font-size: 0.75rem;
  }
`

export const IconWrapper = styled.div`
  color: #304ffc;
  border: 3px solid;
  border-radius: 50%;
  margin-bottom: 2rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BottomContent = styled.div`
  text-align: center;
`

export const SlugInput = styled.input`
  background: #ffffff;
  color: #304ffc;
  padding: 0.75rem;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgb(50 50 93 / 25%) 0 2px 4px 0;
  border: 1px solid #c6c6c6;
  margin-bottom: 1rem;

  &:focus {
    outline: none;
  }
`

export const ErrorMessage = styled.div`
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
`
