import { chunk } from 'lodash'

export const drawPolygon = (context, coordinates, isActive) => {
  const coordinatePairs = chunk(coordinates, 2)

  context.beginPath()

  coordinatePairs.forEach((coordinatePair, index) => {
    const [x, y] = coordinatePair

    if (index === 0) {
      context.moveTo(x, y)
    } else {
      context.lineTo(x, y)
    }
  })

  context.fillStyle = isActive ? '#ffb60bb3' : '#ffb60b40'
  context.lineWidth = 4

  context.fill()
  context.stroke()
  context.closePath()
}

export const drawPolygonsClipped = (context, coordinateLists) => {
  context.beginPath()

  context.strokeStyle = '#ffb60b'
  context.lineWidth = 4

  coordinateLists.forEach((coordinateList) => {
    const coordinatePairs = chunk(coordinateList, 2)

    coordinatePairs.forEach((coordinatePair, index) => {
      const [x, y] = coordinatePair

      if (index === 0) {
        context.moveTo(x, y)
      } else {
        context.lineTo(x, y)
      }
    })
  })

  context.stroke()
  context.clip()
  context.closePath()
}

export const drawTouchPoint = (context, coordinates, isActive) => {
  const center = getPolygonCenter(coordinates)

  context.beginPath()
  context.fillStyle = isActive ? '#ffb60b4d' : '#ff224c4d'
  context.arc(...center, 20, 0, Math.PI * 2, true)
  context.fill()

  context.beginPath()
  context.fillStyle = '#ffffff'
  context.arc(...center, 9.5, 0, Math.PI * 2, true)
  context.fill()
  context.closePath()

  context.beginPath()
  context.fillStyle = isActive ? '#ffb60b' : '#fe224c'
  context.arc(...center, 6, 0, Math.PI * 2, true)
  context.fill()
  context.closePath()
}

export const getCoordinatePairsFromPolygon = (polygon, scale = 1) => {
  const coordinates = getCoordinatesFromPolygon(polygon, scale)
  const coordinatePairs = chunk(coordinates, 2)

  return coordinatePairs
}

export const getCoordinatesFromPolygon = (polygon, scale = 1) => {
  const coordinates = polygon
    .split(',')
    .map((coordinate) => parseInt(coordinate * scale, 10))

  return coordinates
}

export const getGridByCoordinates = (grids, clickCoordinates, scale = 1) => {
  let gridClicked

  grids.forEach((grid) => {
    const coordinatesScaled = getCoordinatesFromPolygon(
      grid.Coordinates,
      scale
    ).join(',')

    const isPointInRectangle = getIsPointInRectangle(
      coordinatesScaled,
      clickCoordinates
    )

    if (isPointInRectangle) {
      gridClicked = grid
    }
  })

  return gridClicked
}

export const getIsOverlapping = (polygon1, polygon2) => {
  let isOverlapping = false
  const coordinatesPairs1 = getCoordinatePairsFromPolygon(polygon1)
  const coordinatesPairs2 = getCoordinatePairsFromPolygon(polygon2)

  const bottom1 = coordinatesPairs1[2][1]
  const bottom2 = coordinatesPairs2[2][1]
  const left1 = coordinatesPairs1[0][0]
  const left2 = coordinatesPairs2[0][0]
  const right1 = coordinatesPairs1[1][0]
  const right2 = coordinatesPairs2[1][0]
  const top1 = coordinatesPairs1[0][1]
  const top2 = coordinatesPairs2[0][1]

  if (left1 <= left2 && right1 >= left2) {
    if (
      (top1 <= top2 && bottom1 >= top2) ||
      (top2 <= top1 && bottom2 >= top1)
    ) {
      isOverlapping = true
    }
  }

  if (left2 <= left1 && right2 >= left1) {
    if (
      (top1 <= top2 && bottom1 >= top2) ||
      (top2 <= top1 && bottom2 >= top1)
    ) {
      isOverlapping = true
    }
  }

  return isOverlapping
}

export const getIsPointInPolygons = (
  context,
  coordinateLists,
  clickCoordinates
) => {
  const [x, y] = clickCoordinates
  const polygon = getPolygon(coordinateLists)
  const isPointInPolygon = context.isPointInPath(polygon, x, y)

  return isPointInPolygon
}

export const getIsPointInRectangle = (coordinates, clickCoordinates) => {
  let isPointInRectangle = false
  const coordinatePairs = getCoordinatePairsFromPolygon(coordinates)
  const [clickX, clickY] = clickCoordinates
  const minMaxCoordinatePairs = getMinMaxCoordinatePairs(coordinatePairs)

  if (
    clickX >= minMaxCoordinatePairs.minX &&
    clickX <= minMaxCoordinatePairs.maxX &&
    clickY > minMaxCoordinatePairs.minY &&
    clickY < minMaxCoordinatePairs.maxY
  ) {
    isPointInRectangle = true
  }

  return isPointInRectangle
}

export const getMinMaxCoordinatePairs = (coordinatePairs) => {
  const minMaxCoordinatePairs = {
    minX: coordinatePairs[0][0],
    minY: coordinatePairs[0][1],
    maxX: coordinatePairs[0][0],
    maxY: coordinatePairs[0][1],
  }

  coordinatePairs.forEach((coordinatePair) => {
    const [x, y] = coordinatePair

    if (x < minMaxCoordinatePairs.minX) {
      minMaxCoordinatePairs.minX = x
    }

    if (y < minMaxCoordinatePairs.minY) {
      minMaxCoordinatePairs.minY = y
    }

    if (x > minMaxCoordinatePairs.maxX) {
      minMaxCoordinatePairs.maxX = x
    }

    if (y > minMaxCoordinatePairs.maxY) {
      minMaxCoordinatePairs.maxY = y
    }
  })

  return minMaxCoordinatePairs
}

export const getPolygon = (coordinateLists) => {
  const polygon = new Path2D()

  coordinateLists.forEach((coordinatePairs) => {
    coordinatePairs.forEach((coordinatePair, index) => {
      const [x, y] = coordinatePair

      if (index === 0) {
        polygon.moveTo(x, y)
      } else {
        polygon.lineTo(x, y)
      }
    })
  })

  return polygon
}

export const getPolygonCenter = (coordinates) => {
  const coordinatePairs = chunk(coordinates, 2)
  const minMaxCoordinatePairs = getMinMaxCoordinatePairs(coordinatePairs)
  const x = (minMaxCoordinatePairs.minX + minMaxCoordinatePairs.maxX) / 2
  const y = (minMaxCoordinatePairs.minY + minMaxCoordinatePairs.maxY) / 2

  return [x, y]
}

export const getRectangleFromPolygon = (coordinates) => {
  const coordinatePairs = getCoordinatePairsFromPolygon(coordinates)
  const minMaxCoordinatePairs = getMinMaxCoordinatePairs(coordinatePairs)

  return [
    ...[minMaxCoordinatePairs.minX, minMaxCoordinatePairs.minY],
    ...[minMaxCoordinatePairs.maxX, minMaxCoordinatePairs.minY],
    ...[minMaxCoordinatePairs.maxX, minMaxCoordinatePairs.maxY],
    ...[minMaxCoordinatePairs.minX, minMaxCoordinatePairs.maxY],
    ...[minMaxCoordinatePairs.minX, minMaxCoordinatePairs.minY],
  ]
}
