export const SET_PAGE_DATE = 'SET_PAGE_DATE'
export const FETCH_WELCOME_DATA = 'FETCH_WELCOME_DATA'
export const SET_WELCOME_DATA = 'SET_WELCOME_DATA'
export const FETCH_CLAIM = 'FETCH_CLAIM'
export const SET_CLAIM_DATA = 'SET_CLAIM_DATA'
export const REQUEST_VIN = 'REQUEST_VIN'
export const SET_REQUEST_VIN = 'SET_REQUEST_VIN'
export const RETRY_VIN_REQ = 'RETRY_VIN_REQ'
export const REQUEST_ODOMETER = 'requestodometer'
export const CONFIRM_ODOMETER = 'CONFIRM_ODOMETER'
export const CONFIRM_ODOMETER_SUBMIT = 'CONFIRM_ODOMETER_SUBMIT'
export const FETCH_DATA_FAILED = 'FETCH_DATA_FAILED'
export const GET_QUESTION_REQ = 'GET_QUESTION_REQ'
export const GET_DATA_FROM_API = 'GET_DATA_FROM_API'
export const PHOTOS_REQUESTED = 'PHOTOS_REQUESTED'
export const GET_ESTIMATE = 'GET_ESTIMATE_DATE'
export const GET_ESTIMATE_NEXT = 'GET_ESTIMATE_NEXT'
export const GET_VEHICLE = 'GET_VEHICLE'

export const REST_STATE = 'REST_STATE'

export const GET_YEARS = 'GET_YEARS'
export const SET_YEARS = 'SET_YEARS'

export const SET_AUTO_PART_ID = 'SET_AUTO_PART_ID'
export const SET_SLUG_ID = 'SET_SLUG_ID'
