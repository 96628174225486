import { useContext, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import AppLayout from 'components/AppLayout'
import { ROUTES } from 'config/routes'
import { AppContext } from 'context/AppContext'

import DamageDetail from './DamageDetail'
import DamageVehicle from './DamageVehicle'
import Estimate from './Estimate'
import EstimateNext from './EstimateNext'
import GetVehicle from './GetVehicle'
import Home from './Home'
import IdentifyVehicle from './IdentifyVehicle'
import RequirePhoto from './RequirePhoto'
import Vehicle from './Vehicle'
import Welcome from './Welcome'

const withRoute = (WrappedComponent) => {
  return (props) => {
    const { history, match } = props
    const { params } = match

    const {
      dispatch,
      state: { autoPartId, slug },
      types,
    } = useContext(AppContext)

    useEffect(() => {
      if (!slug && !params.slugId) {
        history.push(ROUTES.home)
      } else if (params.slugId) {
        dispatch({
          payload: params.slugId,
          type: types.SET_SLUG_ID,
        })
      }

      if (!autoPartId && params.autoPartId) {
        dispatch({
          payload: params.autoPartId,
          type: types.SET_AUTO_PART_ID,
        })
      }
    }, [autoPartId, params.autoPartId, params.slugId, slug])

    return <WrappedComponent {...props} />
  }
}

const App = () => {
  return (
    <AppLayout>
      <Switch>
        <Route
          component={withRoute(IdentifyVehicle)}
          exact
          path={ROUTES.identify}
        />
        <Route
          component={withRoute(DamageVehicle)}
          exact
          path={ROUTES.damage}
        />
        <Route
          component={withRoute(DamageDetail)}
          exact
          path={ROUTES.damagedetail}
        />
        <Route
          component={withRoute(RequirePhoto)}
          exact
          path={ROUTES.requirephotos}
        />
        <Route
          component={withRoute(GetVehicle)}
          exact
          path={ROUTES.getvehicle}
        />
        <Route component={withRoute(Welcome)} path={ROUTES.welcome} exact />
        <Route component={withRoute(Vehicle)} path={ROUTES.vehicle} exact />
        <Route component={withRoute(Estimate)} path={ROUTES.estimate} exact />
        <Route component={withRoute(EstimateNext)} path={ROUTES.next} exact />
        <Route component={withRoute(Home)} path={ROUTES.home} exact />
        <Route component={Home} path="*" />
      </Switch>
    </AppLayout>
  )
}

export default App
