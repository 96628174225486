import { useRef } from 'react'
import Webcam from 'react-webcam'

import CameraIcon from 'assets/icons/CameraIcon'
import CloseIcon from 'assets/icons/CloseIcon'
import mask from 'assets/images/mask.svg'
import AppModal from 'components/AppModal'
import { PageTitle } from 'components/common/styled'
import { dataURL2File } from 'utils/format'

import { CloseButton, ModalContent } from './styled'

const videoConstraints = {
  facingMode: 'environment',
}

const ScanVinModal = ({ onCancel, onSubmit, onChangeMode, screen }) => {
  const webcamRef = useRef()

  const takePhoto = async () => {
    if (webcamRef.current) {
      const imgSrc = webcamRef.current.getScreenshot()
      if (imgSrc) {
        const fileToUpload = await dataURL2File(
          imgSrc,
          new Date().toUTCString()
        )
        onSubmit(fileToUpload)
      }
    }
  }

  return (
    <AppModal>
      <ModalContent>
        <div className="top-wrapper">
          <CloseButton onClick={onCancel}>
            <CloseIcon />
          </CloseButton>
          <div className="top-inner">
            <PageTitle>
              {screen === 'requestVIN'
                ? 'Scan Vehicle VIN'
                : 'Scan Vehicle Odometer'}
            </PageTitle>
            <p>Take a photo when camera aligned.</p>
          </div>
        </div>
        <Webcam
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{
            height: '100vh',
            width: '100%',
            objectFit: 'fill',
            position: 'absolute',
          }}
          videoConstraints={videoConstraints}
        />
        <div className="bottom-wrapper">
          <div className="bottom-inner">
            <p onClick={onChangeMode} role="presentation">
              Having trouble?
              <br />
              {screen === 'requestVIN'
                ? 'Try typing the VIN manually.'
                : 'Try typing the Odometer manually.'}
            </p>
            <div
              className="take-photo-btn"
              onClick={takePhoto}
              role="presentation"
            >
              <CameraIcon />
              <span>Take Photo</span>
            </div>
          </div>
        </div>
        <img alt="" src={mask} draggable={false} />
      </ModalContent>
    </AppModal>
  )
}

export default ScanVinModal
