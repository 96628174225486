import { ToastContainer } from 'react-toastify'

import AppHeader from 'components/AppHeader/'

import { AppLayoutContainer, HeaderWrapper, MainContent } from './styled'

const AppLayout = ({ children }) => {
  return (
    <AppLayoutContainer id="vestimate-app">
      <HeaderWrapper>
        <AppHeader />
      </HeaderWrapper>
      <MainContent id="main-content">{children}</MainContent>
      <ToastContainer />
    </AppLayoutContainer>
  )
}

export default AppLayout
