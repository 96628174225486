import DotLoader from 'react-spinners/DotLoader'

import { SpinnerContainer } from './styled'

const Spinner = () => {
  return (
    <SpinnerContainer>
      <DotLoader color="#304ffc" />
    </SpinnerContainer>
  )
}

export default Spinner
