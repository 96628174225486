import { useContext, useEffect, useState, useMemo } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'

import AppFooter from 'components/AppFooter'
import { PageTitle, StyledInput, SubTitle } from 'components/common/styled'
import Spinner from 'components/Spinner'
import Steps from 'components/Steps'
import { AppContext } from 'context/AppContext'
import {
  getMakesFromDataApi,
  getModelsFromDataApi,
  getStylesFromDataApi,
  getVehicleByVinApi,
  saveVehicleApi,
} from 'context/services/appServices'
import useAppHistory from 'hooks/useAppHistory'

import ExModal from './ExModal'

import {
  Container,
  TopWrapper,
  Content,
  InputWrapper,
  FindVehicle,
  SelectFormContainer,
  FieldRow,
} from './styled'

const GetVehicle = () => {
  const history = useAppHistory()

  const { dispatch, state, types } = useContext(AppContext)

  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState(null)
  const [make, setMake] = useState(null)
  const [makeItems, setMakeItems] = useState([])
  const [model, setModel] = useState(null)
  const [modelItems, setModelItems] = useState([])
  const [showEx, setShowEx] = useState(false)
  const [showSpin, setShowSpin] = useState(false)
  const [vin, setVin] = useState('')
  const [year, setYear] = useState(null)

  const [yOts] = useMemo(() => {
    const yKeys = Object.keys(state.years)
      .sort((a, b) => b - a)
      .map((k) => ({
        value: state.years[k],
        label: k,
      }))
    return [yKeys]
  }, [state.years])

  const valid = useMemo(
    () => !!year && !!make && !!model && !!category,
    [year, make, model, category]
  )

  useEffect(() => {
    if (state.slug) {
      dispatch({
        type: types.GET_VEHICLE,
      })
    }
  }, [state.slug])

  const onChangeCategory = (evt) => {
    setVin('')
    setCategory(evt)
  }

  const onChangeMake = async (evt) => {
    try {
      setVin('')
      setMake(evt)
      setShowSpin(true)
      setModel(null)
      setCategory(null)
      const params = { year: year.value, make: evt.value }
      const { data } = await getModelsFromDataApi(params)
      setModelItems(data.map((ite) => ({ label: ite.model, value: ite.model })))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setShowSpin(false)
    }
  }

  const onChangeModel = async (evt) => {
    try {
      setVin('')
      setModel(evt)
      setShowSpin(true)
      setCategory(null)
      const params = { year: year.value, make: make.value, model: evt.value }
      const { data } = await getStylesFromDataApi(params)

      const items = data.map((ite) => ({
        label: ite.style,
        value: ite.vehicleId,
      }))
      setCategories(items)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setShowSpin(false)
    }
  }

  const onChangeYear = async (evt) => {
    try {
      setVin('')
      setYear(evt)
      setMake(null)
      setModel(null)
      setCategory(null)
      setShowSpin(true)
      const { data } = await getMakesFromDataApi({ year: evt.value })
      setMakeItems(data.map((ite) => ({ label: ite.make, value: ite.make })))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setShowSpin(false)
    }
  }

  const onChangeVin = async (evt) => {
    const { value } = evt.target
    setVin(evt.target.value)
    if (/^[a-zA-Z0-9]*$/.test(value) === false) {
      const err = 'Vincode should be alphanumberic and length should be 17.'
      toast.error(err, { toastId: 'invalidvin' })
      setVin('')
    } else if (value.length === 17) {
      try {
        setShowSpin(true)
        const res = await getVehicleByVinApi(value)
        if (res.code === 200) {
          setMake({ label: res.data.make, value: res.data.make })
          setModel({ label: res.data.model, value: res.data.model })
          setCategory({ label: res.data.style, value: res.data.vehicleId })
          setYear({ label: res.data.year, value: res.data.year })
          setVin(res.data.vin)
          setShowSpin(false)
        } else {
          reset()
          toast.error(res.data.error, { toastId: 'invalidvin' })
        }
      } catch (error) {
        reset()
      }
    }
  }

  const onSubmit = async () => {
    try {
      setShowSpin(true)
      const payload = {
        code: 200,
        data: {
          AutoVIN: vin,
          AutoYear: year.value,
          AutoID: category.value,
          slug: state.slug,
        },
      }
      const { data } = await saveVehicleApi(payload)
      history.push('damage', data.slug)
    } catch (error) {
      setShowSpin(false)
      toast.error('Unable to fetch brands')
    }
  }

  const reset = () => {
    setVin('')
    setYear(null)
    setMake(null)
    setModel(null)
    setCategory(null)
    setShowSpin(false)
  }

  if (state.loading) {
    return <Spinner />
  }

  return (
    <Container>
      <TopWrapper>
        <Steps />
        <PageTitle>Enter your VIN</PageTitle>
      </TopWrapper>
      <Content>
        <InputWrapper>
          <StyledInput
            disabled={showSpin}
            onChange={onChangeVin}
            placeholder="VIN"
            value={vin}
          />
          <FindVehicle onClick={() => setShowEx(true)}>
            How do I find my VIN?
          </FindVehicle>
        </InputWrapper>
        <SelectFormContainer>
          <SubTitle>Select Year, Make &amp; Model</SubTitle>
          <FieldRow>
            <Select
              name="year"
              onChange={onChangeYear}
              options={yOts}
              placeholder="Select Year..."
              value={year}
            />
          </FieldRow>
          <FieldRow>
            <Select
              name="make"
              onChange={onChangeMake}
              options={makeItems}
              placeholder="Make"
              value={make}
            />
          </FieldRow>
          <FieldRow>
            <Select
              name="model"
              onChange={onChangeModel}
              options={modelItems}
              placeholder="Model"
              value={model}
            />
          </FieldRow>
          <FieldRow>
            <Select
              name="category"
              onChange={onChangeCategory}
              options={categories}
              placeholder="Category"
              value={category}
            />
          </FieldRow>
        </SelectFormContainer>
      </Content>
      <AppFooter disabled={!valid} onClick={onSubmit} text="Next" />
      {showEx && <ExModal onClose={() => setShowEx(false)} />}
      {showSpin && <Spinner />}
    </Container>
  )
}

export default GetVehicle
