export const getIsIncludedGrid = (grids, grid) => {
  let isIncludedGrid = false

  grids.forEach((gridActive) => {
    if (gridActive.id === grid.id) {
      isIncludedGrid = true
    }
  })

  return isIncludedGrid
}
