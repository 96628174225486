import styled from 'styled-components'

export const VehicleContainer = styled.div``

export const InnerContainer = styled.div`
  padding: 0 1.5rem;
`

export const PropertyRow = styled.div`
  :not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }

  :last-child > p {
    margin-bottom: 0;
  }

  legend {
    color: #999999;
  }

  p {
    font-weight: 700;
  }

  :not(:first-child) {
    margin-top: 1.5rem;
  }
`
