import styled from 'styled-components'

// Injected Markup.CSS from API-response overrides base font-size of 16px, so layout heights cannot use rem.

export const AppLayoutContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  overflow: auto;
  height: 100vh;
  position: relative;
  background: #f8f8f8;
`

export const HeaderWrapper = styled.div`
  height: 72px; // height: 4.5rem;
  position: fixed;
  max-width: 500px;
  z-index: 1010;
  width: 100%;
`

export const MainContent = styled.div`
  height: calc(100vh - 72px); // height: calc(100vh - 4.5rem);
  padding-top: 72px; // padding-top: 4.5rem;
  overflow: auto;
`
