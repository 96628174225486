import { useContext, useEffect, useRef, useState } from 'react'

import AppFooter from 'components/AppFooter'
import DisclaimerModal from 'components/modals/DisclaimerModal'
import Spinner from 'components/Spinner'
import Steps from 'components/Steps'
import { AppContext } from 'context/AppContext'
import { getEstimateHtmlApi } from 'context/services/appServices'
import useAppHistory from 'hooks/useAppHistory'
import { getScriptsFromStr } from 'utils/format'

import { Container, EstimateContent } from './styled'

const Estimate = () => {
  const history = useAppHistory()

  const { dispatch, state, types } = useContext(AppContext)

  const containerRef = useRef()

  const [showModal, setShowModal] = useState(false)

  const handleNext = () => {
    history.push('next')
  }

  const loadData = async () => {
    try {
      const htmlStr = await getEstimateHtmlApi(state.estimateHtml)
      if (containerRef.current) {
        containerRef.current.innerHTML = getScriptsFromStr(htmlStr)
      }
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  }

  useEffect(() => {
    if (state.slug) {
      dispatch({
        type: types.GET_ESTIMATE,
      })
    }
  }, [state.slug])

  useEffect(() => {
    if (state.estimateHtml) {
      loadData()
    }
  }, [state.estimateHtml])

  useEffect(() => {
    if (state.disclaimer && state.showDisclaimer === 1) {
      setShowModal(true)
    }
  }, [state.disclaimer, state.showDisclaimer])

  if (!state.estimateHtml || state.loading) {
    return <Spinner />
  }

  return (
    <Container>
      <Steps fakeSteps={4} />
      <EstimateContent ref={containerRef} />
      <AppFooter onClick={handleNext} text="Next" />

      {showModal && (
        <DisclaimerModal
          onClose={() => setShowModal(false)}
          text={state.disclaimer}
        />
      )}
    </Container>
  )
}

export default Estimate
