export const ROUTES = {
  damage: '/damage/:slugId',
  damagedetail: '/damagedetail/:slugId/:autoPartId',
  estimate: '/estimate/:slugId',
  getvehicle: '/get_vehicle/:slugId',
  home: '/',
  identify: '/identify/:slugId',
  next: '/next/:slugId',
  requestodometer: '/identify_odometer/:slugId',
  requirephotos: '/requirephotos/:slugId',
  vehicle: '/vehicle/:slugId',
  welcome: '/welcome/:slugId',
}
