import styled from 'styled-components'

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo_wrapper {
    max-width: 70%;
    margin-top: 1.25rem;

    img {
      width: 100%;
    }
    span {
      color: rgb(48, 79, 253);
    }
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 343px;
    height: 193px;
    border-radius: 30px;
    box-shadow: 0 25px 64px rgba(0, 0, 0, 0.13);
    overflow: hidden;
  }

  .app_description {
    margin-bottom: 4rem;
    text-align: center;
    font-size: 1.5rem;

    p {
      padding: 2rem 0;
      max-width: 60%;
      margin: 0;
    }
  }
`
