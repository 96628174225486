import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 2rem;
  height: 100%;
`

export const EstimateContent = styled.div`
  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-top: 0;
  }

  #estimateHtml {
    width: 100%;
    background: #ffffff;
    font-size: 1.25rem;
    border-radius: 20px;
    display: block;
    border-collapse: collapse;
    padding: 2rem 2rem 1rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

    tbody {
      display: table;
      width: 100%;

      tr {
        &:last-child,
        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          > th {
            font-weight: 700;
            color: #000000;
            opacity: 0.4;
          }
        }

        td {
          padding: 0.5rem 0;
        }

        hr {
          margin: 0;
        }
      }
    }
  }

  .disclaimer_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1.5rem;

    a {
      color: #304ffc;
      font-weight: 700;
      text-align: center;
    }

    .disclaimer {
      font-size: 0.75rem;
    }
  }
`
