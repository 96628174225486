import styled from 'styled-components'

import { Card, SubTitle } from 'components/common/styled'

export const Container = styled(Card)`
  position: relative;
  width: 80%;
  padding-bottom: 0;
`

export const Image = styled.img`
  vertical-align: middle;
  max-width: 100%;
  max-height: 200px;
`

export const ImageWrapper = styled.div`
  margin: 0.75rem auto;
  text-align: center;
`

export const ModalFooter = styled.div`
  text-align: center;
  border-top: 1px solid #dddddd;
  padding: 1.25rem 0;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  cursor: pointer;
  color: ${({ color }) => color};
`

export const ModalTitle = styled(SubTitle)`
  text-align: center;
`
