const ArrowIcon = (props) => {
  return (
    <svg
      width="29"
      height="19"
      viewBox="0 0 29 19"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path
        fill="none"
        d="M28 9.5L1.5 9.5M9.5 18L1 9.5L9.5 1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowIcon
