import { useEffect } from 'react'
import { createPortal } from 'react-dom'

import { OverlayWrapper } from './styled'

const AppModal = ({ children, position = 'center' }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return createPortal(
    <OverlayWrapper position={position}>{children}</OverlayWrapper>,
    document.getElementById('vestimate-app')
  )
}

export default AppModal
