import { useMemo } from 'react'

import { formatAssetUrl } from 'utils/format'

import { ImageList, ImageWrapper, RequireItem } from './styled'

const Photos = ({ items, size, noText, borderColor, selected, onSelect }) => {
  const itemsToRender = useMemo(() => {
    return items?.filter((x) => x.id) || []
  }, [items])

  return (
    <ImageList>
      {itemsToRender.map((item) => (
        <RequireItem
          key={item.id}
          selected={selected && selected.id === item.id}
          onClick={() => {
            if (onSelect) {
              onSelect(item)
            }
          }}
        >
          <ImageWrapper size={size} borderColor={borderColor} noMargin={noText}>
            <img src={formatAssetUrl(item.img)} alt="" />
          </ImageWrapper>
          {!noText && <div className="image-name">{item.text}</div>}
        </RequireItem>
      ))}
    </ImageList>
  )
}

export default Photos
