import styled from 'styled-components'

const btnStyles = `
  border-radius: 30px;
  border: 1px solid;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
  padding: 1rem;
  text-align: center;
  width: 100%;
`

export const ActionButtonPrimary = styled.div`
  ${btnStyles}

  background-color: #304ffc;
  color: #ffffff;
`

export const ActionButtonSecondary = styled.div`
  ${btnStyles}

  background-color: #ffffff;
  color: #304ffc;
`

export const IconWrapper = styled.div`
  color: #00e376;
  margin-bottom: 1.5rem;
`

export const ModalContent = styled.div`
  background: #ffffff;
  width: 100%;
  height: 450px;
  max-height: 450px;
  position: relative;
  box-shadow: 0 -18px 112px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.75rem;
`

export const ModalDesc = styled.div`
  font-size: 1.25rem;
  opacity: 0.8;
  max-width: 320px;
  text-align: center;
  margin-bottom: 2rem;
`

export const ModalTitle = styled.div`
  font-size: 1.5rem;
  opacity: 0.8;
  font-weight: 700;
  margin-bottom: 1.25rem;
`
