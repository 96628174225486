import { useReducer } from 'react'
import { isArray } from 'lodash'

const useReducerWithMiddleware = (reducer, initialState, middlewareFns) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const dispatchWithMiddleware = (action) => {
    if (isArray(middlewareFns)) {
      middlewareFns.forEach((middlewareFn) =>
        middlewareFn(action, state, dispatch)
      )
    } else if (typeof middlewareFns === 'function') {
      middlewareFns(action, state, dispatch)
    }
    dispatch(action)
  }

  return [state, dispatchWithMiddleware]
}

export default useReducerWithMiddleware
