import BaseApi from './baseApi'

const client = new BaseApi()

export const confirmOdometerApi = (data) =>
  client.post('v3/data/confirmodometer', data)

export const confirmOdometerSubmitApi = (data) =>
  client.post('v3/data/confirmodometersubmit', data)

export const getAutoPartDamageLocationApi = (data) =>
  client.post('v3/data/autopartdamagelocation', data)

export const getBirdseyeAutoApi = (data) =>
  client.post('v3/data/birdseyeauto', data)

export const getClaimApi = (data) => client.post('v3/data/getclaim', data)

export const getEstimateApi = (data) =>
  client.post('v3/estimate/estimate', data)

export const getEstimateHtmlApi = (path) => client.get(path)

export const getMakesFromDataApi = (data) =>
  client.get('v3/vehicle/makesfromdata', data)

export const getModelsFromDataApi = (data) =>
  client.get('v3/vehicle/modelsfromdata', data)

export const getNextApi = (data) => client.post('v3/estimate/next', data)

export const getPhotosRequestedApi = (data) =>
  client.post('v3/data/photosrequested', data)

export const getStylesFromDataApi = (data) =>
  client.get('v3/vehicle/stylesfromdata', data)

export const getQuestionApi = (data) => client.post('v3/data/question', data)

export const getVehicleApi = (data) => client.post('v3/data/getvehicle', data)

export const getVehicleByVinApi = (vin) =>
  client.get(`v3/vehicle/vin?vin=${vin}`)

export const getWelcomeApi = (data) => client.post('v3/data/welcome', data)

export const getYearsApi = () => client.get('v3/vehicle/years')

export const requestOdometerApi = (data) =>
  client.post('v3/data/requestodometer', data)

export const requestVinApi = (data) => client.post('v3/data/requestvin', data)

export const requestVinRetryApi = (data) =>
  client.post('v3/data/vinretry', data)

export const saveAutoPartApi = (data) => client.post('v3/data/autopart', data) // inconsistently named API endpoint

export const saveDamageLevelApi = (data) =>
  client.post('v3/data/savedamagelevel', data)

export const saveDamageLocationApi = (data) =>
  client.post('v3/data/savedamagelocation', data)

export const saveSnapshot = (data) =>
  client.post('v3/data/snapshotupload', data)

export const saveVehicleApi = (data) => client.post('v3/data/savevehicle', data)

export const uploadFileApi = ({ data, config, file, path }) => {
  return client.upload(`v3/data/${path}`, data, file, config)
}
