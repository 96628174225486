import styled from 'styled-components'

// Injected Markup.CSS from API-response overrides base font-size of 16px, so layout heights cannot use rem.

export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.75rem;
  min-height: 72px; // min-height: 4.5rem;

  background: ${({ boxshadow }) => boxshadow && ' #ffffff'};
  ${({ boxshadow }) => {
    return boxshadow
      ? 'box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;'
      : ''
  }}
`

export const LeftContent = styled.div`
  position: absolute;
  left: 0.75rem;
  display: flex;
  align-items: stretch;
`

export const Logo = styled.img`
  cursor: pointer;
`

export const MidContent = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
`

export const RightContent = styled.div`
  position: absolute;
  right: 0.75rem;
  display: flex;
  align-items: stretch;
`

export const ActionBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  color: #999999;
  cursor: pointer;
`

export const SupportContainer = styled(ActionBtn)`
  position: relative;
`

export const SupportImageWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SupportImage = styled.img`
  max-width: 2rem;
  max-height: 2rem;
`

export const SupportDropdown = styled.div`
  min-width: 160px;
  top: 100%;
  right: -0.75rem;
  position: absolute;
  margin-top: 0.25rem;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  background-color: #ffffff;
  opacity: 0;
  height: 0;
  transition: all 0.3s;

  &.show {
    opacity: 1;
    height: auto;
  }

  ul {
    padding: 0.5rem 0;
    margin: 0;

    li {
      list-style: none;
      padding: 0.5rem 0.75rem;
      margin-top: 0.25rem;
    }
  }
`
