import { useContext, useEffect, useState } from 'react'

import { PageTitle } from 'components/common/styled'
import ImageMap from 'components/ImageMap'
import DamageModal from 'components/modals/DamageModal'
import PopupIntroModal from 'components/modals/PopupIntroModal'
import Spinner from 'components/Spinner'
import Steps from 'components/Steps'
import { AppContext } from 'context/AppContext'
import {
  getAutoPartDamageLocationApi,
  saveAutoPartApi,
  saveDamageLevelApi,
} from 'context/services/appServices'
import useAppHistory from 'hooks/useAppHistory'
import { formatAssetUrl } from 'utils/format'

import { CarWrapper, Container, TopWrapper } from './styled'

const DamageVehicle = () => {
  const history = useAppHistory()

  const { dispatch, state, types } = useContext(AppContext)

  const [canShowInfoModal, setCanShowInfoModal] = useState(true)
  const [modalData, setModalData] = useState(null)
  const [section, setSection] = useState(null)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showSpin, setShowSpin] = useState(false)

  const nextStep = async (AutoPartID) => {
    const payload = {
      code: 200,
      data: { slug: state.slug, autoPartID: AutoPartID },
    }

    try {
      const { data } = await getAutoPartDamageLocationApi(payload)

      data.autoPartId = AutoPartID

      dispatch({
        payload: data,
        type: types.SET_PAGE_DATE,
      })

      setShowSpin(false)

      history.push('damagedetail', state.slug, AutoPartID)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const onSelectPart = async (currentSelection) => {
    const part = currentSelection.AutoPartID

    setShowSpin(true)

    try {
      setSection(part)
      saveDamageSelection(part)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  const saveDamageSelection = async (AutoPartID) => {
    const payload = {
      code: 200,
      data: { slug: state.slug, autoPartID: AutoPartID },
    }

    try {
      const { data } = await saveAutoPartApi(payload)

      setModalData(data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      setShowSpin(false)
    }
  }

  const saveDamageSeverity = async (AutoPartID, level) => {
    const payload = {
      code: 200,
      data: {
        slug: state.slug,
        autoPartID: AutoPartID,
        damageLevel: level.answer,
      },
    }

    setShowSpin(true)
    setModalData(null)

    try {
      await saveDamageLevelApi(payload)
      await nextStep(AutoPartID)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  useEffect(() => {
    const mainContent = document.getElementById('main-content')
    const footerContainer = document.getElementById('footer-container')

    if (!footerContainer) {
      mainContent.style.height = '100vh'
    }

    if (state.slug) {
      dispatch({
        type: types.GET_QUESTION_REQ,
      })
    }
  }, [state.slug])

  useEffect(() => {
    if (state?.popupIntro?.on === 1 && canShowInfoModal) {
      setShowInfoModal(true)
      setCanShowInfoModal(false)
    }
  }, [state?.popupIntro?.on, canShowInfoModal])

  if (!state.screen || state.loading || !state.map) {
    return <Spinner />
  }

  const mapData = Object.keys(state.map).map((key) => state.map[key])

  return (
    <Container>
      <TopWrapper>
        <Steps />
        <PageTitle>{state.sectionLabel}</PageTitle>
      </TopWrapper>
      <CarWrapper>
        <ImageMap
          height={state.height}
          imageSrc={formatAssetUrl(state.img)}
          mapData={mapData}
          onSelectPart={onSelectPart}
          showTouchPoint
          width={state.width}
        />
      </CarWrapper>
      {showSpin && <Spinner />}
      {showInfoModal && (
        <PopupIntroModal
          data={state.popupIntro}
          onClose={() => setShowInfoModal(false)}
        />
      )}
      {modalData && (
        <DamageModal
          data={modalData}
          onClose={() => setModalData(null)}
          onSubmit={(level) => {
            saveDamageSeverity(section, level)
          }}
          position="top"
        />
      )}
    </Container>
  )
}

export default DamageVehicle
