import {
  confirmOdometerApi,
  getBirdseyeAutoApi,
  getClaimApi,
  getEstimateApi,
  getNextApi,
  getPhotosRequestedApi,
  getQuestionApi,
  getVehicleApi,
  getWelcomeApi,
  getYearsApi,
  requestOdometerApi,
  requestVinApi,
  requestVinRetryApi,
} from './services/appServices'
import * as types from './constants'

const fetchWelcome = async (action, state, dispatch) => {
  try {
    const data = {
      code: 200,
      data: { slug: state.slug },
    }
    const response = await getWelcomeApi(data)
    if (response && response.code === 200) {
      dispatch({
        type: types.SET_WELCOME_DATA,
        payload: response.data,
      })
    } else {
      dispatch({
        type: types.SET_WELCOME_DATA,
        payload: null,
      })
    }
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const fetchClaim = async (action, state, dispatch) => {
  try {
    const data = {
      code: 200,
      data: { slug: state.slug },
    }
    const response = await getClaimApi(data)
    dispatch({
      type: types.SET_CLAIM_DATA,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const requestVin = async (action, state, dispatch) => {
  try {
    const data = {
      code: 200,
      data: { slug: state.slug },
    }
    const response = await requestVinApi(data)
    if (response && response.code === 200) {
      dispatch({
        type: types.SET_REQUEST_VIN,
        payload: response.data,
      })
    }
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const requestVinRetry = async (action, state, dispatch) => {
  try {
    const data = {
      code: 200,
      data: { slug: state.slug },
    }
    const response = await requestVinRetryApi(data)

    dispatch({
      type: types.SET_PAGE_DATE,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const getOdometer = async (action, state, dispatch) => {
  try {
    const data = {
      code: 200,
      data: { slug: state.slug },
    }
    const response = await requestOdometerApi(data)

    dispatch({
      type: types.SET_PAGE_DATE,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const confirmOdometer = async (action, state, dispatch) => {
  try {
    const data = {
      code: 200,
      data: { slug: state.slug },
    }
    const response = await confirmOdometerApi(data)

    dispatch({
      type: types.SET_PAGE_DATE,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const getYears = async (action, state, dispatch) => {
  try {
    const response = await getYearsApi()
    dispatch({
      type: types.SET_YEARS,
      payload: response.data.years,
    })
  } catch (error) {
    dispatch({
      type: types.SET_YEARS,
      payload: [],
    })
  }
}

const getData = async (action, state, dispatch) => {
  try {
    const postData = {
      code: 200,
      data: {
        slug: state.slug,
        location: 'exterior',
      },
    }
    const response = await getBirdseyeAutoApi(postData)
    if (response && response.code === 200) {
      dispatch({
        type: types.SET_PAGE_DATE,
        payload: response.data, // ,
      })
    }
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const getQuestion = async (action, state, dispatch) => {
  try {
    const params = {
      code: 200,
      data: { slug: state.slug, call: 'preDamage' },
    }
    const response = await getQuestionApi(params)
    if (
      response.question &&
      response.question.length > 0 &&
      response.numbersOfQuestions > 0
    ) {
      // TODO: something
    } else {
      getData(action, state, dispatch)
    }
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const photosRequested = async (action, state, dispatch) => {
  try {
    const data = {
      code: 200,
      data: {
        AutoPartID: state.autoPartId,
        slug: state.slug,
      },
    }
    const response = await getPhotosRequestedApi(data)
    if (response && response.code === 200) {
      dispatch({
        type: types.SET_PAGE_DATE,
        payload: {
          ...response.data,
          autoPartId: state.autoPartId,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const getEstimate = async (action, state, dispatch) => {
  try {
    const postData = {
      code: 200,
      data: {
        slug: state.slug,
      },
    }
    const response = await getEstimateApi(postData)
    if (response && response.code === 200) {
      dispatch({
        type: types.SET_PAGE_DATE,
        payload: response.data,
      })
    }
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const estimateRequest = async (action, state, dispatch) => {
  try {
    const params = {
      code: 200,
      data: { slug: state.slug, call: 'postPhotos' },
    }
    const response = await getQuestionApi(params)
    if (
      response.question &&
      response.question.length > 0 &&
      response.numbersOfQuestions > 0
    ) {
      // TODO: something
    } else {
      getEstimate(action, state, dispatch)
    }
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const estimateNextReq = async (action, state, dispatch) => {
  const params = {
    code: 200,
    data: { slug: state.slug },
  }

  try {
    const response = await getNextApi(params)
    if (response && response.code === 200) {
      dispatch({
        type: types.SET_PAGE_DATE,
        payload: response.data,
      })
    }
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const getVehicle = async (action, state, dispatch) => {
  const params = {
    code: 200,
    data: { slug: state.slug },
  }

  try {
    const response = await getVehicleApi(params)
    if (response && response.code === 200) {
      dispatch({
        type: types.SET_PAGE_DATE,
        payload: response.data,
      })
    }
  } catch (error) {
    dispatch({
      type: types.FETCH_DATA_FAILED,
      payload: null,
    })
  }
}

const appMiddleware = (...args) => {
  const [action] = args

  switch (action.type) {
    case types.FETCH_WELCOME_DATA:
      fetchWelcome(...args)
      break
    case types.FETCH_CLAIM:
      fetchClaim(...args)
      break
    case types.REQUEST_VIN:
      requestVin(...args)
      getYears(...args)
      break
    case types.RETRY_VIN_REQ:
      requestVinRetry(...args)
      break
    case types.REQUEST_ODOMETER:
      getOdometer(...args)
      break
    case types.CONFIRM_ODOMETER:
      confirmOdometer(...args)
      break
    case types.GET_QUESTION_REQ:
      getQuestion(...args)
      break
    case types.GET_DATA_FROM_API:
      getData(...args)
      break
    case types.PHOTOS_REQUESTED:
      photosRequested(...args)
      break
    case types.GET_ESTIMATE:
      estimateRequest(...args)
      break
    case types.GET_ESTIMATE_NEXT:
      estimateNextReq(...args)
      break
    case types.GET_VEHICLE:
      getVehicle(...args)
      getYears(...args)
      break
    default:
      break
  }
}

export default appMiddleware
