import { useContext, useEffect, useMemo, useState } from 'react'

import { AppContext } from 'context/AppContext'

import ManualConfirm from './ManualConfirm'
import ScanConfirm from './ScanConfirm'

import { IdentifyVehicleContainer, InnerContainer } from './styled'

const IdentifyVehicle = () => {
  const { dispatch, state, types } = useContext(AppContext)

  const [mode, setMode] = useState('scan')

  const onChangeMode = (modeUpdated) => {
    setMode(modeUpdated)
  }

  const InnerComp = useMemo(() => {
    if (mode === 'manual') {
      return ManualConfirm
    }

    return ScanConfirm
  }, [mode])

  useEffect(() => {
    if (state.slug) {
      dispatch({
        type: types.REQUEST_VIN,
      })
    }
  }, [state.slug])

  return (
    <IdentifyVehicleContainer>
      <InnerContainer>
        <InnerComp onChangeMode={onChangeMode} />
      </InnerContainer>
    </IdentifyVehicleContainer>
  )
}

export default IdentifyVehicle
