import { useContext, useEffect, useState } from 'react'

import AppFooter from 'components/AppFooter'
import { PageTitle, PageDescription } from 'components/common/styled'
import TakePhotoModal from 'components/modals/TakePhotoModal'
import Photos from 'components/Photos'
import Spinner from 'components/Spinner'
import Steps from 'components/Steps'
import { AppContext } from 'context/AppContext'
import useAppHistory from 'hooks/useAppHistory'

import { Container, Content, TopWrapper } from './styled'

const RequirePhoto = () => {
  const history = useAppHistory()

  const { dispatch, state, types } = useContext(AppContext)

  const [openModal, setOpenModal] = useState(false)

  const handleNext = () => {
    history.push('estimate')
  }

  useEffect(() => {
    if (state.slug) {
      dispatch({
        payload: state,
        type: types.PHOTOS_REQUESTED,
      })
    }
  }, [state.slug])

  if (!state.screen || state.loading) {
    return <Spinner />
  }

  return (
    <Container>
      <TopWrapper>
        <Steps />
        <PageTitle>Required Photos</PageTitle>
        <PageDescription className="page-desc">{state.desc}</PageDescription>
      </TopWrapper>
      <Content>
        <Photos
          items={state.photos || []}
          onSelect={() => setOpenModal(true)}
        />
      </Content>
      <AppFooter onClick={handleNext} text="Next" />
      {openModal && (
        <TakePhotoModal
          onClose={() => setOpenModal(false)}
          photos={state.photos || []}
        />
      )}
    </Container>
  )
}

export default RequirePhoto
