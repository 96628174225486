import { PageTitle } from 'components/common/styled'
import AppModal from 'components/AppModal'
import useAppHistory from 'hooks/useAppHistory'

import { ModalAction, ModalBody, ModalContent } from './styled'

const DisclaimerModal = ({ text, onClose }) => {
  const history = useAppHistory()

  const goToHome = () => {
    history.push('home')
  }

  return (
    <AppModal>
      <ModalContent>
        <ModalBody>
          <PageTitle>Disclaimer</PageTitle>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </ModalBody>
        <ModalAction>
          <div onClick={onClose} role="presentation">
            Agree
          </div>
          <div onClick={goToHome} role="presentation">
            Cancel
          </div>
        </ModalAction>
      </ModalContent>
    </AppModal>
  )
}

export default DisclaimerModal
