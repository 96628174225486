import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.75rem 0;

  ul {
    display: flex;
    list-style: none;
    margin: 0;

    li {
      margin: 0 0.75rem;
    }
  }
`

export const DotTagDiv = styled.div`
  height: 6px;
  border-radius: 4px;
  width: ${({ current }) => (current ? '1.5rem' : '6px')};
  background: ${({ current, completed }) =>
    current || completed ? '#304ffc' : '#c6c6c6'};
`
