import styled from 'styled-components'

export const Button = styled.button`
  width: 200px;
  border: 1px solid ${({ disabled }) => (disabled ? '#c6c6c6' : '#304ffc')};
  border-radius: 30px;
  padding: 1rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  user-select: none;
  cursor: pointer;
  background-color: ${({ disabled }) => (disabled ? '#ffffff' : '#304ffc')};
  color: ${({ disabled }) => (disabled ? '#c6c6c6' : '#ffffff')};
`

export const Card = styled.div`
  background: #ffffff;
  border-radius: 0.75rem;
  padding: 1.75rem 1.25rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
`

export const ExtraFooterLink = styled.div`
  padding: 2rem;
  color: #304ffc;
  font-size: 1.25rem;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
`

export const PageDescription = styled.p`
  color: #000000;
`

export const PageTitle = styled.h3`
  margin: 0;
  text-align: center;
  margin-bottom: ${({ mb }) => mb || '2rem'};
  font-size: 1.5rem;
`

export const StyledInput = styled.input`
  background: #ffffff;
  color: #304ffc;
  padding: 0.75rem;
  width: 100%;
  border-radius: 5px;
  box-shadow: rgb(50 50 93 / 25%) 0 2px 4px 0;
  border: 1px solid #c6c6c6;
  margin-bottom: 1rem;

  &:focus {
    outline: none;
  }
`

export const SubTitle = styled.h4`
  margin: 0;
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
`
