import styled from 'styled-components'

export const HtmlContent = styled.div`
  max-height: 300px;
  overflow: auto;
`

export const ModalAction = styled.div`
  display: flex;
  user-select: none;
  justify-content: center;
  border-top: 1px solid #c6c6c6;

  > div {
    padding: 1.25rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: #304ffc;
  }
`

export const ModalBody = styled.div`
  padding: 1.25rem;
`

export const ModalContent = styled.div`
  background: #ffffff;
  width: 80%;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
