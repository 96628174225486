import * as types from './constants'

const appReducer = (state, { type, payload }) => {
  switch (type) {
    case types.FETCH_WELCOME_DATA:
    case types.FETCH_CLAIM:
    case types.REQUEST_VIN:
    case types.RETRY_VIN_REQ:
    case types.GET_QUESTION_REQ:
    case types.PHOTOS_REQUESTED:
    case types.GET_VEHICLE:
      return {
        years: state.years,
        slug: state.slug,
        loading: true,
      }
    case types.SET_REQUEST_VIN:
    case types.SET_CLAIM_DATA:
    case types.SET_WELCOME_DATA:
    case types.SET_PAGE_DATE:
      return {
        ...payload,
        years: state.years,
        slug: state.slug,
        loading: false,
      }
    case types.REST_STATE:
      return {
        years: state.years,
        slug: state.slug,
        loading: false,
      }
    case types.SET_YEARS:
      return {
        ...state,
        years: payload,
      }
    case types.SET_AUTO_PART_ID:
      return {
        ...state,
        autoPartId: payload,
      }
    case types.SET_SLUG_ID:
      return {
        ...state,
        slug: payload,
      }
    default:
      return state
  }
}

export default appReducer
