import styled from 'styled-components'

export const ModalAction = styled.div`
  border-top: 1px solid #c6c6c6;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  cursor: pointer;
  user-select: none;

  > div {
    padding: 1.25rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: #c6c6c6;

    &:first-child {
      color: #304ffc;
    }
  }
`

export const ModalBody = styled.div`
  padding: 1.25rem;
`

export const ModalContent = styled.div`
  background: #ffffff;
  width: 80%;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
