import { useContext, useEffect, useRef } from 'react'

import Spinner from 'components/Spinner'
import { AppContext } from 'context/AppContext'
import { getEstimateHtmlApi } from 'context/services/appServices'
import { getScriptsFromStr } from 'utils/format'

import { Container, Content } from './styled'

const EstimateNext = () => {
  const { dispatch, state, types } = useContext(AppContext)
  const containerRef = useRef()

  const loadData = async () => {
    try {
      const htmlStr = await getEstimateHtmlApi(state.estimateHtml)
      if (containerRef.current) {
        containerRef.current.innerHTML = getScriptsFromStr(htmlStr)
      }
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  }

  useEffect(() => {
    if (state.slug) {
      dispatch({
        type: types.GET_ESTIMATE_NEXT,
      })
    }
  }, [state.slug])

  useEffect(() => {
    if (state.estimateHtml) {
      loadData()
    }
  }, [state.estimateHtml])

  if (!state.estimateHtml || state.loading) {
    return <Spinner />
  }

  return (
    <Container>
      <Content ref={containerRef} />
    </Container>
  )
}

export default EstimateNext
